import React from 'react';
import ResumeLearning from './resumeLearning';
import { useNavigate } from 'react-router-dom';

function Saved({ Courses }: any) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/app/enrollment/courseId');
    }
    return (
        <div className="mt-5 w-full grid gap-2 pb-5">
            {Courses.recommended.map((el: any) => (<ResumeLearning isSaved={true} handleClick={handleClick} course={el} />))}
        </div>
    );
}

export default Saved;
