
import row1 from "../../../assets/icons/row1.svg";
import row2 from "../../../assets/icons/row2.svg";
import row3 from "../../../assets/icons/row3.svg";
import { h2Style, h4Style, pStyle } from "../../waitList/style";

type Props = {}

export default function SectionFour({}: Props) {
  return (
    <section className='lg:p-[100px] p-[50px] bg-[#FFFEE0]'>
        <h2 className={`${h2Style} text-center`}>Why choose SCRiiPO</h2>
        <div className='lg:mt-[4rem] mt-[3.5rem] grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-[10rem] gap-[3rem]'>
            <div>
                <img src={row1} alt="level" />
                <h4 className={`${h4Style} mt-3`}>Tailored for any skill level</h4>
                <p className={`${pStyle} mt-3`}>
                    Whether you're just starting your journey in music or looking to advance your expertise, 
                    our platform is designed to meet your needs by ensuring that learners at all levels 
                    have the resources and support they need to succeed.
                </p>
            </div>
            <div>
                <img src={row2} alt="flexible" />
                <h4 className={`${h4Style} mt-3`}>Flexible learning</h4>
                <p className={`${pStyle} mt-3`}>
                Learn at your own pace with our flexible online courses. 
                Whether you have a busy schedule or prefer a more intensive learning experience, 
                we accommodate your needs with self-paced and structured course options.
                </p>
            </div>
            <div>
                <img src={row3} alt="learnable" />
                <h4 className={`${h4Style} mt-3`}>Learn from the best</h4>
                <p className={`${pStyle} mt-3`}>
                SCRiiPO features experienced and renowned music professionals and educators in the industry. 
                Learn from professionals with extensive knowledge and real-world experience who are 
                dedicated to helping you succeed.
                </p>
            </div>
        </div>
    </section>
  )
}