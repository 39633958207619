import React from 'react'
import { LandingPageLayout } from '../../../components/layouts'
import ReviewSection from '../landing/ReviewSection'
import SectionOne from './SectionOne'

type Props = {}

export default function index({}: Props) {
  return (
    <LandingPageLayout>
        <div className='-mt-[11rem] lg:pt-0 pt-[10rem] lg:pb-[2rem] bg '>
            <SectionOne />
        </div>
        <div className='md:mt-[13rem]'>
            <ReviewSection />
        </div>
    </LandingPageLayout>
  )
}