import React from "react";
import { RiVisaLine } from "react-icons/ri";

function SubscripionPlan({ changePlans }: any) {
  return (
    <div className="w-full flex flex-col gap-10 pt-4 supreme">
      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Plan
          </h1>
          <p className="supreme text-[12px] font-normal text-gray-600">
            You are currently subscribed to a monthly premium plan
          </p>
        </div>
        <div className="bg-[#F1F2F3] h-[20vh] md:w-[70%] p-4 px-5 flex  gap-5 pt-6 rounded-lg justify-between ">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col">
              <p className="supreme text-[10px] font-normal text-gray-600">
                Current plan
              </p>
              <p className="supreme text-[16px] font-semibold text-gray-800 tracking-wide">
                Premium
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="supreme text-[11px] font-semibold text-gray-800">
                Frequency : Monthly
              </p>
              <p className="supreme text-[11px] font-semibold text-gray-800">
                Next billing date : July 22, 2024
              </p>
              <p className="supreme text-[11px] font-semibold text-gray-800">
                Amount : $200
              </p>
            </div>
          </div>
          <div>
            <button className="bg-[#1C7272] text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme">
              Change plan
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Payment method
          </h1>
          <p className="supreme text-[12px] font-normal text-gray-600">
            This card is currently being charged for your subscription
          </p>
        </div>
        <div className="bg-[#F1F2F3] h-[10vh] md:w-[70%] p-4 px-5 flex  gap-5 rounded-lg justify-between items-center ">
          <div className="flex flex-col gap-3">
            <div className="flex  items-center gap-3">
              <RiVisaLine size={24} color="blue" />
              <p className="supreme text-[14px] font-semibold text-gray-800 tracking-wide">
                **** **** **** 5632
              </p>
            </div>
          </div>
          <div>
            <button
              className="bg-[#1C7272] text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
              onClick={changePlans}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Cancel subscription
          </h1>
        </div>
        <div className="bg-[#F1F2F3] h-[10vh] md:w-[70%] p-4 px-5 flex  gap-5 rounded-lg justify-between items-center ">
          <div className="flex flex-col gap-3">
            <p className="supreme text-[12px] font-normal text-gray-600 tracking-wide">
              You can cancel your subscription at any time
            </p>
          </div>
          <div>
            <button className="bg-[#F93232] text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscripionPlan;
