import React, { useState } from 'react';
import logo from "../../../assets/images/logo-mini.svg";
import hamburgerIcon from "../../../assets/icons/hamburger.svg"
import { Link } from 'react-router-dom';
import { NAV_LINKS } from '../../../utils/constants';
import dropdownIcon from '../../../assets/icons/dropdown.svg';
import dropdownUpIcon from '../../../assets/icons/dropdown-up.svg';

type Props = {}

export default function Navbar({}: Props) {
    const [ isClicked, setIsClicked ] = useState(0);

    return (
        <nav className='w-full lg:h-[70px] h-[50px] flex items-center bg-transparent relative lg:top-2 top-3 z-[9999]'>
            <div className='lg:w-[90%] w-[97%] mx-auto bg-white rounded-[20px] h-full flex items-center justify-between px-[20px] py-[15px]'>
                <div className='flex items-center gap-x-[6rem]'>
                    <Link to="/">
                        <img src={logo} alt="logo" className='lg:w-fit w-[70%] hover:cursor-pointer' />
                    </Link>

                    <ul className='lg:flex hidden items-center gap-x-10'>
                    {NAV_LINKS.map((item) => (
                        <li key={item.id}>
                        {!item.isDropdown ? 
                            <Link to={item.to} className='font-[supreme]'>{item.link_name}</Link> : 
                            <button 
                            onClick={() => {
                                isClicked === item.id ?
                                setIsClicked(0) :
                                setIsClicked(item.id)
                            }} 
                            className="flex items-center gap-x-2 font-[supreme]"
                            >
                                <>{item.link_name}</>
                                <img src={
                                    isClicked === item.id ? 
                                    dropdownIcon : 
                                    dropdownUpIcon
                                } alt="dropdown" />
                            </button>
                        }
                        </li>
                    ))}
                    </ul>
                </div>
                <div className='flex items-center gap-x-3'>
                    <Link className="text-tsa-primary p-[12px] font-[500] lg:block hidden rounded-[8px] font-[supreme] text-[14px] tracking-wide" to="/auth/signin">Log In</Link>
                    <Link className="bg-tsa-primary p-[12px] lg:block hidden rounded-[8px] font-[supreme] text-white text-[14px] tracking-wide" to="/auth/signup">Sign up now</Link>
                    <img src={hamburgerIcon} alt="collapse icon" className='lg:hidden block' />
                </div>
            </div>
        </nav>
    )
}