import React from 'react'
import { LandingPageLayout } from '../../../components/layouts';
import gospel from "../../../assets/images/gospelBeatz.png";
import { h1Style, h2Style, pStyle } from '../../waitList/style';
import { ABOUT_SCRIIPO } from '../../../utils/constants';

type Props = {}

export default function About({}: Props) {
  return (
    <LandingPageLayout>
        <section className='-mt-[10.7rem] lg:h-[80vh] lg:pt-[230px] pt-[150px] h-[50vh] bg_about_img'>
            <h1 className={`${h1Style} text-center text-white lg:w-[60%] w-[80%] mx-auto`}>{ABOUT_SCRIIPO.HEADER}</h1>
        </section>
        <section className='lg:py-[100px] py-[50px]'>
            <h2 className={`${h2Style} text-center lg:pb-[4rem] pb-[2rem]`}>Our story.</h2>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-7 items-center'>
                <div className='col-span-1'>
                    <div className='background-img_'>
                        <img src={gospel} alt="girl_listening_to_music" className='mx-auto w-[75%]' />
                    </div>
                </div>
                <div className='col-span-1'>
                    <p className={`${pStyle} lg:text-left text-center lg:w-[75%] w-[80%] lg:mx-0 mx-auto lg:pl-[100px] lg:pr-0`}>{ABOUT_SCRIIPO.OUR_STORY}</p>
                </div>
            </div>
        </section>
        <section className='py-[60px]'>
            <h2 className={`${h2Style} text-center mb-5`}>Our Mission.</h2>
            <p className={`${pStyle} lg:w-[60%] w-[80%] mx-auto text-center`}>{ABOUT_SCRIIPO.OUR_MISSION}</p>
        </section>
    </LandingPageLayout>
  )
}