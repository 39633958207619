import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { CXM, } from '../../../../utils/constants';
import CourseCard from './CourseCard';

function ContinueReading({ coursesHistory }: any) {
    const responsive = {
        0: { items: 1 },
        450: { items: 2 },
        800: { items: 3 },
        1024: { items: 4 },
    }
    return (
        <div className='grid gap-3 mb-5'>
            <h1 className='item-center flex justify-start supreme font-[500] text-[20px]'>Continue learning</h1>
            <div className='relative w-full overflow-hidden'>
                <AliceCarousel
                    mouseTracking
                    responsive={responsive}
                    controlsStrategy="responsive"
                    autoPlay={true}
                    autoPlayInterval={5000}
                    infinite={true}
                    keyboardNavigation={true}
                >
                    {coursesHistory?.data?.map((item: any) => (
                        <CourseCard course={item} showProgress={true} />
                    ))}

                </AliceCarousel>
            </div>
        </div>
    )
}

export default ContinueReading