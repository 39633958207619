import { useQuery, useMutation, QueryClient } from "react-query";
import AuthService from "../../services/auth";

const queryClient = new QueryClient();
const authService = new AuthService();

export const useLogin = () => {
  return useMutation((data: any) => authService.login(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};


export const useLogout = () => {
  return useMutation(() => authService.logout(), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};

export const useGetProfile = () => {
  return useQuery("profile", () => authService.getProfile());
};
