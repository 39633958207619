import React from 'react';

type Props = {}

export default function LoadingPage({}: Props) {
    return (
        <div className='mx-auto grid gap-y-4'>
            <div className='border-gradient rounded-full h-[64px] w-[64px] mx-auto animate-spin relative'>
                <div className='bg-white rounded-full h-[98%] w-[92%] left-[10%] right-[10%] absolute mx-auto'></div>
            </div>

            <p className='supreme text-center hover:cursor-pointer lg:text-[18px] text-[16px] text-[#555C74]  font-[500]'>
                Personalizing your learning experience...
            </p>
        </div>
    )
}