import { useQuery, useMutation, QueryClient } from "react-query";
import CourseService from "../../services/course.service";

const queryClient = new QueryClient();
const courseService = new CourseService();

export const useWatchLesson = (id: string) => {
  return useQuery(["watchLesson", id], () => courseService.watchLesson(id));
};

export const useTrackProgress = () => {
  return useMutation(
    ({ id, payload }: { id: string; payload: any }) =>
      courseService.trackProgress(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("trackProgress");
      },
    }
  );
};

export const useCommentOnLesson = () => {
    return useMutation(({ id, payload }: {id: string, payload: any}) => courseService.commentOnLesson(id, payload), {
        onSuccess: () => {
            queryClient.invalidateQueries("comments");
        },
    });
};

export const useGetAllCourses = () => {
  return useQuery("allCourses", () => courseService.getAllCourses());
};

export const useGetSingleCourse = (id: string) => {
  return useQuery(["singleCourse", id], () =>
    courseService.getSingleCourse(id)
  );
};

// New Hooks
export const useGetRecommendedCourses = () => {
  return useQuery("recommendedCourses", () =>
    courseService.getRecommendedCourses()
  );
};

export const useGetPopularCourses = () => {
  return useQuery("popularCourses", () => courseService.getPopularCourses());
};

export const useGetCoursesHistory = () => {
  return useQuery("coursesHistory", () => courseService.getCoursesHistory());
};
