import React, { useEffect, useState } from 'react';
import { appLinks } from '../../../utils/constants';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../../../assets/images/logo-mini.svg';

const gridStyle = '';
const linkListStyle = 'flex items-center gap-x-3 w-[80%] mx-auto hover:bg-tsa-primary hover:text-white rounded-[10px] py-3.5 px-4 tracking-wide delay-100 ease-in';
const linkContainerStyle = 'grid gap-y-3 w-full mt-[4rem]';
const linkNameStyle = 'lg:block hidden text-[14px] font-[300] leading-[24.55px] capitalize';

type SidebarProps = {
    setSidebarOpen: (isOpen: boolean) => void;
    isSidebarOpen: boolean
};

export default function Sidebar({ setSidebarOpen, isSidebarOpen }: SidebarProps) {
    const location = useLocation();
    const [currentHover, setCurrentHover] = useState<number | null>(null);

    return (
        <aside className={`bg-cp-primary w-[220px] z-[10001] bg-white shadow-md h-full fixed top-0 left-0 bottom-0 transform transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } md:translate-x-0`}>
            <div className='flex justify-between p-4'>
                <Link to='/'>
                    <img src={logoImg} alt="logo" className='' />
                </Link>
                <button onClick={() => setSidebarOpen(false)} className='md:hidden'>
                    <img src='/images/close.svg' alt='Close' className='h-6 w-6' />
                </button>
            </div>

            <div className="flex flex-col py-10 justify-center items-center gap-2 border-b-[0.8px] border-cp-secondary md:hidden pb-5">
                <div className="h-10 w-10 rounded-full bg-tsa-primary text-white flex items-center justify-center">
                    AV
                </div>
                <div className='grid'>
                    <p className="capitalize font-bold text-[14px]">Asuquo Victor</p>
                    <p className="text-[12px] capitalize ">@talktoasuquo</p>
                </div>
                <div>
                    <h1 className="text-[12px] capitalize text-black">John Doe</h1>
                </div>
            </div>
            <div className={gridStyle}>
                <div className={linkContainerStyle}>
                    {appLinks.map((item) => {
                        const targetRoute = location.pathname.includes(item.route);
                        return (
                            <Link
                                key={item.id}
                                to={item.route}
                                onMouseOver={() => setCurrentHover(item.id)}
                                onMouseOut={() => setCurrentHover(null)}
                                className={`${linkListStyle} ${targetRoute ? 'text-[#F0F1F5] bg-tsa-primary' : 'text-tsa-textGrey'}`}>
                                <img
                                    src={targetRoute || currentHover === item.id ? require(`../../../assets/icons/on.${item.img}`) : require(`../../../assets/icons/${item.img}`)}
                                    alt={item.name}
                                />
                                <p className={linkNameStyle}>{item.name}</p>
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className='flex items-center'>
                <div className='flex mt-[1rem] w-fit py-1.5 border-b-[2px] border-[#F0F1F5]'>
                </div>
            </div>
        </aside>
    );
}
