import React, { useState } from "react";
import { IoCamera } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateProfile } from "../../../../hooks/react-query/useAuthUser";
import Loader from "../../../../components/ui/Loader";
import { toast } from "react-toastify";
import { Modal, Box, Button, CircularProgress } from "@mui/material";
import { handleUpload } from "../../../../services/uploadFiles";

interface PersonalDetailsProps {
  data: any;
  refetch: any;
}

function PersonalDetails({ data: userData, refetch }: PersonalDetailsProps) {
  const [shouldEdit, setShouldEdit] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState(userData?.data?.image || "");
  const { mutate, data, isLoading } = useUpdateProfile();

  const formik = useFormik({
    initialValues: {
      firstname: userData?.data?.firstname || "",
      lastname: userData?.data?.lastname || "",
      email: userData?.data?.email || "",
      headline: userData?.data?.headline || ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string(),
      lastname: Yup.string(),
      email: Yup.string(),
      headline: Yup.string()
    }),
    onSubmit: values => {
      mutate({ ...values, image }, {
        onSuccess: () => {
          setShouldEdit(true);
          refetch();
          toast("Update successful");
        }
      });
    }
  });

  // const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setImageLoading(true);
  //   try {
  //     const file = e.target.files && e.target.files[0];
  //     if (file) {
  //       const res = await handleUpload(file);
  //       setImage(res.url);
  //       setIsModalOpen(false);
  //     }
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   } finally {
  //     setImageLoading(false);
  //   }
  // };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full flex flex-col gap-3 pt-4 supreme">
      <h1 className="supreme text-[16px] font-semibold text-gray-700">Personal details</h1>
      <div className="bg-[#F1F2F3] h-[70vh] md:w-[70%] p-4 flex flex-col gap-5 pt-6 rounded-md">
        <div className="flex gap-5 items-center">
          <div className="relative w-max">
            <img
              className="h-[65px] w-[65px] rounded-[1000px] bg-[#F1F2F3] text-white flex items-center justify-center"
              src={image || `https://api.dicebear.com/8.x/initials/svg?seed=${userData?.data?.firstname}-${userData?.data?.lastname}`}
              alt=""
              onClick={() => setIsModalOpen(true)}
            />
            <div className="bg-[#667185] w-max h-max p-[3px] rounded-full absolute -right-[4px] bottom-[3px] border border-white">
              <IoCamera color="white" size={12} onClick={() => setIsModalOpen(true)} />
            </div>
          </div>
          <div className="supreme flex flex-col gap-2">
            <p className="text-[18px] font-bold">{userData?.data?.firstname} {userData?.data?.lastname}</p>
            {shouldEdit && (
              <button
                className="bg-[#1C7272] text-[10px] flex py-[5px] text-white items-center gap-1 px-2 rounded-[5px] w-max"
                onClick={() => setShouldEdit(false)}
              >
                <CiEdit size={14} />
                Edit profile
              </button>
            )}
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">First name</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] rounded-md w-[70%] p-2 supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${shouldEdit ? "bg-white" : "bg-white"}`}
              placeholder="Enter first name"
              {...formik.getFieldProps("firstname")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Last name</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] w-[70%] rounded-md p-2 supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${shouldEdit ? "bg-white" : "bg-white"}`}
              placeholder="Enter last name"
              {...formik.getFieldProps("lastname")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Email</p>
            <input
              type="email"
              disabled={shouldEdit}
              className={`text-[14px] w-[70%] p-2 rounded-md supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${shouldEdit ? "bg-white" : "bg-white"}`}
              placeholder="Enter email"
              {...formik.getFieldProps("email")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Headline</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] w-[70%] p-2 pb-20 rounded-md supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${shouldEdit ? "bg-white" : "bg-white"}`}
              placeholder="Tell us about yourself"
              {...formik.getFieldProps("headline")}
            />
          </div>
          {!shouldEdit && (
            <button
              type="submit"
              className="bg-[#1C7272] text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
            >
              Save changes
            </button>
          )}
        </form>
      </div>
      {/* <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center gap-4" sx={{ width: 300, margin: 'auto', marginTop: '20vh' }}>
          <h2 className="text-lg font-semibold supreme">Upload Image</h2>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          {imageLoading && <CircularProgress />}
          <Button variant="contained" color="primary" onClick={() => setIsModalOpen(false)}>Close</Button>
        </Box>
      </Modal> */}
    </div>
  );
}

export default PersonalDetails;
