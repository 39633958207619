import React from 'react'
import { h2Style, h3Style, pStyle } from './style';
import access from "../../assets/icons/_access.svg";
import enrollment from "../../assets/icons/_enrollment.svg";
import discount from "../../assets/icons/_discount.svg";
import exclusive from "../../assets/icons/_exclusive.svg";

type Props = {}

export default function SectionFour({}: Props) {
  return (
    <section className='lg:py-[100px] lg:px-[50px] py-[30px] px-[10px]'>
        <h2 className={`${h2Style} text-center`}>Why join the waitlist?</h2>
        <p className={`${pStyle} text-center`}>Here’s a number of reasons you should join the waitlist</p>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-[24px] mt-[4rem]'>
            <div className='bg-[#F5FFFA] py-[70px]'>
                <img src={access} alt="access" className='mx-auto' />
                <h3 className={`${h3Style} text-center mt-7 mb-2`}>Early Access</h3>
                <p className={`${pStyle} text-center lg:w-[57%] w-[90%] mx-auto`}>Be the first to explore our comprehensive courses and resources as soon as we launch.</p>
            </div>
            <div className='bg-[#FFFAFA] py-[70px]'>
                <img src={exclusive} alt="exclusive" className='mx-auto' />
                <h3 className={`${h3Style} text-center mt-7 mb-2`}>Exclusive Content</h3>
                <p className={`${pStyle} text-center lg:w-[57%] w-[90%] mx-auto`}>Gain access to special content, previews, and insider information available only to our waitlist members.</p>
            </div>
            <div className='bg-[#FFFEE0] py-[70px]'>
                <img src={enrollment} alt="enrollment" className='mx-auto' />
                <h3 className={`${h3Style} text-center mt-7 mb-2`}>Priority Enrollment</h3>
                <p className={`${pStyle} text-center lg:w-[57%] w-[90%] mx-auto`}>Secure your spot in our most sought-after courses and masterclasses before they fill up.</p>
            </div>
            <div className='bg-[#F0FFF0] py-[70px]'>
                <img src={discount} alt="discount" className='mx-auto' />
                <h3 className={`${h3Style} text-center mt-7 mb-2`}>Discounts & Offers</h3>
                <p className={`${pStyle} text-center lg:w-[57%] w-[90%] mx-auto`}>Enjoy exclusive discounts and special offers available only to waitlist members.</p>
            </div>
        </div>
    </section>
  )
}