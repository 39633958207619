import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo-footer.svg";
import { LANDING_FOOTER } from '../../../utils/constants';

type Props = {}

export default function Footer({}: Props) {
    return (
        <footer className='pt-[70px] bg-tsa-primary px-[2rem]'>
            <div className='flex flex-wrap flex-row pb-[6rem] gap-y-8'>
                <div className="lg:w-[20%] w-full">
                    <img src={logo} alt="logo" />
                </div>
                
                <div className="lg:w-[80%] w-full flex flex-wrap gap-x-4 gap-y-7 justify-between lg:px-[50px] px-[20px]">
                    {/* company  */}
                    <div className='grid gap-y-4'>
                        <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px]'>Company</p>
                        <ul className="grid gap-5">
                            {LANDING_FOOTER.company.map((item, i) => (
                                <li key={i}>
                                    <Link to={item.to} className='flex items-start gap-2'>
                                        <p className='font-[supreme] text-[#DCDEE2] font-light'>{item.name}</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* legal  */}
                    <div>
                        <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px] mb-4' >Legal</p>
                        <ul className="grid gap-y-5">
                            {LANDING_FOOTER.legal.map((item, i) => (
                                <li key={i}>
                                    <Link to={item.to} className='items-start gap-2 font-[supreme] font-light text-[#DCDEE2]'>
                                       {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* support  */}
                    <div>
                        <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px] mb-4'>Support</p>
                        <ul className="grid">
                            {LANDING_FOOTER.support.map((item, i) => (
                                <li key={i}>
                                    <Link to={item.to} className='items-start gap-2 font-[supreme] font-light text-[#DCDEE2]'>
                                       {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* connect  */}
                    <div className='grid gap-y-4'>
                        <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px]'>Connect</p>
                        <ul className="grid gap-5">
                            {LANDING_FOOTER.connect.map((item, i) => (
                                <li key={i}>
                                    <Link to={item.to} className='flex items-start gap-2'>
                                        <img src={require(`../../../assets/icons/${item.src}`)} alt={item.name} />
                                        <p className='font-[supreme] text-[#DCDEE2]  font-light'>{item.name}</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* download */}
                    <div>
                        <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px] mb-4'>Download</p>
                        <ul className="grid gap-y-3">
                            {LANDING_FOOTER.download.map((item, i) => (
                                <li key={i}>
                                    <Link to={item.to} className='items-start gap-2 font-[supreme] text-[#DCDEE2] '>
                                        <img src={require(`../../../assets/images/${item.src}`)} alt={item.alt} />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='pb-[4rem] pt-[2rem] border-t-[.5px] border-[#DCDEE2]'>
                <p className='font-[supreme] text-[#DCDEE2]'>© 2024 SCRiiPO. All Rights Reserved.</p>
            </div>
        </footer>
    )
}