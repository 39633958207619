import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import ContinueReading from './continueReading'
import Recommended from './recommended'
import Popular from './popular'
import TrackLearning from './TrackLearning'
import CompleteProfile from './CompleteProfile'
import Loader from '../../../../components/ui/Loader'
import { useGetCoursesHistory, useGetPopularCourses, useGetRecommendedCourses } from '../../../../hooks/react-query/useCourse'


type Props = {}

export default function Home({ }: Props) {
  const { data: recommendedCourses, isLoading: isLoadingRecommended } = useGetRecommendedCourses();
  const { data: popularCourses, isLoading: isLoadingPopular } = useGetPopularCourses();
  const { data: coursesHistory, isLoading: isLoadingHistory } = useGetCoursesHistory();

  if (isLoadingRecommended || isLoadingPopular || isLoadingHistory) {
    return <Loader />;
  }

  return (
    <AppLayout>
      <div className='my-[70px] mx-[20px] pb-[100px]'>
        <div className='w-[90%] flex items-center gap-5 h-[110px] my-5'>
          <TrackLearning />
          <CompleteProfile />
        </div>
        <ContinueReading coursesHistory={coursesHistory} />
        <Recommended recommendedCourses={recommendedCourses} />
        <Popular popularCourses={popularCourses} />
      </div>

    </AppLayout>
  )
}