import { useQuery } from "react-query";
import InstructorService from "../../services/instructor.service";

const instructorService = new InstructorService();

export const useGetInstructorPage = (id: string) => {
  return useQuery(
    ["instructorPage", id],
    () => instructorService.getInstructorPage(id),
    {
      enabled: !!id, // Ensures the query only runs if the id is provided
    }
  );
};
