import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../custom-hooks';

const countriesArr = require("../../utils/countries.json");

interface CountryProps {
    name: string,
    emoji: string,
    unicode: string,
    image: string
}

interface Props {
    handleCountryChange: (data: any) => void
}

const boxStyle = 'bg-tsa-background text-[14px] text-[#98A2B3] rounded-[6px] flex items-center gap-x-1 py-2.5';


function CountryDropdown({ handleCountryChange }: Props) {
    const countrySelectRef = useRef(null);
    const [ toggleDropdown, setToggleDropdown ] = useState(false);
    const [ searchQuery, setSearchQuery ] = useState('');
    const [ selectedCountry, setSelectedCountry ] = useState<any>({});
    const [ countries, setCountries ] = useState([]);

    const closeModal = () => {
        setToggleDropdown(false);
        setSearchQuery('');
        setCountries(countriesArr)
    }

    useOnClickOutside(countrySelectRef, closeModal)
    
    const handleCountrySearch = (e: any) => {
        const text = e.target.value;
        setSearchQuery(text.toLowerCase());
    };

    useEffect(() => {
        setCountries(countriesArr);
        setSelectedCountry(countriesArr[0])
        handleCountryChange(countriesArr[0])
    },[]);

    useEffect(() => {
        if(searchQuery === ''){
            setCountries(countriesArr)
        }else{
            const filteredCountries = countriesArr.filter((item: CountryProps) => item.name.toLowerCase().includes(searchQuery));
            setCountries(filteredCountries);
        }
    }, [searchQuery]);


    return (
        <div ref={countrySelectRef} className='relative mt-1'>
             <div
                className={`${boxStyle} hover:cursor-pointer`}
                onClick={() => setToggleDropdown(prevState => !prevState)}
            >
                <img src={selectedCountry.image} alt={`${selectedCountry.name} flag`} className='h-[30px] w-[45px]' />
                <p>{selectedCountry.name}</p>
            </div>
            {toggleDropdown &&
                <div className="max-h-[30vh] h-fit z-20 overflow-y-auto absolute top-[2.7rem] w-full shadow bg-transparent text-ryd-subTextPrimary text-[14px]">
                <input 
                    type="search" 
                    onChange={handleCountrySearch} 
                    className="w-full h-[35px] outline-tsa-background  border-0 bg-gray-200 text-tsa-standard px-[20px]" 
                    placeholder="Search Country..." 
                    />

                {countriesArr.length > 0 && 
                    countries.map((item: CountryProps, index) => (
                        <div key={index} 
                            className="hover:bg-tsa-backgroundLight text-[#98A2B3] bg-tsa-background px-4 py-1 hover:cursor-pointer flex items-center gap-x-1" 
                            onClick={() => {
                                setSelectedCountry(item);
                                setToggleDropdown(false)
                                handleCountryChange(item);
                                }}
                            >
                            <img src={item.image} alt={`${item.name} flag`} className='h-[30px] w-[45px]' />
                            <p>{item.name}</p>
                        </div>
                    )
                )}
                </div>
            }
        </div>
    )
};


export default React.memo(CountryDropdown)

