import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const initialState = {
    user: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: ( state: RootState, action) => {
            state.user = action.payload
        }
    }
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;