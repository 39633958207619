import { DateTime } from "luxon";

export function formatDate(dateString: any) {
  // Parse the input date string
  const parsedDate = DateTime.fromISO(dateString);

  // Format the date as "DD/MM/YYYY"
  const formattedDate = parsedDate.toFormat("dd/MM/yyyy");

  return formattedDate;
}

export function formatDate1(dateString: any) {
  // Parse the input date string
  const parsedDate = DateTime.fromJSDate(new Date(dateString));

  // Format the date in a user-friendly way
  const formattedDate = parsedDate.toFormat(
    "cccc, LLLL dd, yyyy 'at' hh:mm:ss"
  );

  return formattedDate;
}

export function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hoursDisplay = hours > 0 ? `${hours} hr${hours === 1 ? "" : "s"}` : "";
  const minutesDisplay =
    minutes > 0 ? `${minutes} minute${minutes === 1 ? "" : "s"}` : "";
  const secondsDisplay =
    remainingSeconds > 0
      ? `${remainingSeconds} second${remainingSeconds === 1 ? "" : "s"}`
      : "";

  return [hoursDisplay, minutesDisplay, secondsDisplay]
    .filter(Boolean)
    .join(" ");
}
