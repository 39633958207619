import React, { ReactNode } from 'react';
import Navbar from './Navbar';
import Footer from '../../../pages/landingPage/landing/Footer';
import SectionSeven from '../../../pages/waitList/SectionSeven';

interface Props {
    children: ReactNode,
    hideSection?: boolean
}

export default function LandingPageLayout({ children, hideSection }: Props) {
  return (
    <div>
        <Navbar />
        <div className='lg:mt-[100px] mt-[80px]'>{ children }</div>
        {!hideSection && 
        <SectionSeven 
          text="Sign up now"
          to="/auth/signup"
          />
        }
        <Footer />
    </div>
  )
}
