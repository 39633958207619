import React, { useState } from 'react'
import { LandingPageLayout } from '../../../components/layouts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../assets/icons/backArrow.svg";
import { CommentList, CourseCard, VideoPlayer } from '../../../components/ui';
import { btnStyle, h2Style, h3Style, pStyle } from '../../waitList/style';
import shareIcon from "../../../assets/icons/shareIcon.svg";
import saveIcon from "../../../assets/icons/saveIcon.svg";
import levelIcon from "../../../assets/icons/levelIcon.svg";
import reviewIcon from "../../../assets/icons/reviewIcon.svg";
import audioIcon from "../../../assets/icons/speaker.svg";
import durationIcon from "../../../assets/icons/durationIcon.svg";
import girlImg from "../../../assets/images/girlImg.png";
import commentIcon from "../../../assets/icons/commentIcon.svg";
import sliderleft from "../../../assets/icons/slider-left.svg";
import sliderRight from "../../../assets/icons/slider-right.svg";
import { CXM, responsive } from '../../../utils/constants';
import { Pagination, Stack } from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import { useGetSingleCourse } from '../../../hooks/react-query/useCourse';
import Loader from '../../../components/ui/Loader';

type Props = {}

export default function CourseDetail({ }: Props) {
    const { id } = useParams()
    const { data: courseData, isLoading } = useGetSingleCourse(id as string)
    const navigate = useNavigate();

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(4);

    const handleClickNext = () => {
        if (CXM.length > end) {
            setStart(prev => (prev + 1));
            setEnd(prev => (prev + 1));
        }
        return false;
    };
    const handleClickPrevious = () => {
        if (start === 0) {
            return false;
        }
        setStart(prev => (prev - 1));
        setEnd(prev => (prev - 1));
    }

    if (isLoading) {
        return <Loader />
    }

    const course = courseData?.data || {};
    const mainContributor = course.main_contributor || {};
    const courseGroups = course.group || [];

    return (
        <LandingPageLayout>
            <section className='pb-[40px]'>
                <div className='grid lg:grid-cols-2 grid-cols-1 lg:px-[100px] md:px-[50px] px-[30px] items-center font gap-x-10 gap-y-3'>
                    <div>
                        <button onClick={() => navigate(-1)} className='items-center supreme flex gap-x-2 mb-7'>
                            <img src={backIcon} alt="back" />&nbsp; Back to courses
                        </button>
                        <VideoPlayer style="rounded-[12px] lg:h-[400px] h-[200px]" src={course.introductory_video || 'https://www.youtube.com/watch?v=4SXQ_wlbWog'} />
                        <div className='flex items-center gap-x-7 py-4'>
                            <span className='flex items-center gap-x-1.5 supreme'>
                                <img src={saveIcon} alt="save" /> Save
                            </span>
                            <span className='flex items-center gap-x-1.5 supreme'>
                                <img src={shareIcon} alt="share" /> Share
                            </span>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h2 className={`${h2Style}`}>{course.title || 'Beginner Guitar Lessons'}</h2>
                        <p className={`${pStyle} py-1 capitalize`}>By {mainContributor.name || 'John Doe'} {mainContributor.nickname || 'Johnny'}</p>
                        <ul className='grid gap-y-3 py-5'>
                            <li className='flex items-center gap-x-2'>
                                <span className='flex items-center gap-x-1 supreme'>
                                    <img src={levelIcon} alt="level" />&nbsp;
                                    Level
                                </span>
                                <span className='supreme capitalize'>{course.level || 'Beginner'}</span>
                            </li>
                            <li className='flex items-center gap-x-2'>
                                <span className='flex items-center gap-x-1 supreme'>
                                    <img src={reviewIcon} alt="review" />&nbsp;
                                    Review
                                </span>
                                <span className='supreme'>90% positive review</span>
                            </li>
                            <li className='flex items-center gap-x-2'>
                                <span className='flex items-center gap-x-1 supreme'>
                                    <img src={audioIcon} alt="audio" />&nbsp;
                                    Audio
                                </span>
                                <span className='supreme capitalize'>{course.language || 'English'}</span>
                            </li>
                            <li className='flex items-center gap-x-2'>
                                <span className='flex items-center gap-x-1 supreme'>
                                    <img src={durationIcon} alt="duration" />&nbsp;
                                    Duration
                                </span>
                                <span className='supreme'>{(course.lessons || []).length} lessons ({course.duration || '1200'}) Seconds</span>
                            </li>
                        </ul>
                        <Link to={""} className={`w-full supreme flex justify-center mt-3 ${btnStyle}`}>Start your journey</Link>
                    </div>
                </div>
            </section>

            <section className='pb-[40px]'>
                <div className='grid lg:grid-cols-2 grid-cols-1 lg:px-[100px] md:px-[50px] px-[30px] gap-x-10 gap-y-10'>
                    <div>
                        <h3 className={`${h3Style}`}>About this course</h3>
                        <div dangerouslySetInnerHTML={{ __html: course.description || '<p>This course covers the basics of playing the guitar. You will learn about chords, strumming patterns, and basic music theory to get you started on your musical journey.</p>' }} className={`${pStyle} supreme mt-3`} />
                        {/* <div className='mt-7'>
                            <h3 className={`${h3Style}`}>Course content</h3>
                            <ol className='grid gap-y-3 mt-2 list-decimal list-inside font-[400] lg:text-[18px] text-[14px] leading-[24px] text-[#010516]'>
                                {courseGroups.map((el: any, i: number) => (
                                    <li className='flex items-center justify-between' key={i}>
                                        <span className='supreme'>{el?.title || 'Introduction to Guitar'}</span>
                                    </li>
                                ))}
                            </ol>
                            <button className='supreme mt-7 font-[500] text-[16px] leading-[24px] text-[#010516]'>View all {courseGroups.length} lessons &nbsp;&darr;</button>
                        </div> */}
                    </div>
                    <div>
                        <h3 className={`${h3Style}`}>Instructor</h3>
                        <div className='flex items-center gap-x-4 mt-4'>
                            <img src={mainContributor.image || girlImg} alt="author" className='lg:h-[80px] lg:w-[80px] h-[60px] w-[60px] rounded-full object-cover' />
                            <div>
                                <p className='supreme font-[500] text-[20px] leading-[24px] text-[#010516]'>{mainContributor.name || 'John Doe'}</p>
                                <p className='supreme font-[400] text-[16px] leading-[22px] text-[#555C74] mt-1'>{mainContributor.title || 'Guitar Instructor'}</p>
                            </div>
                        </div>
                        <p className={`${pStyle} supreme mt-3 mb-5`}>
                            {mainContributor.detail || 'John has been teaching guitar for over 15 years. His passion for music and teaching has helped hundreds of students achieve their musical goals.'}
                        </p>
                        <Link to={`/instructor/${mainContributor.id || 0}`} className='supreme font-[500] text-[16px] leading-[24px] text-[#010516]'>View profile &nbsp;&rarr;</Link>
                    </div>
                </div>
            </section>

            <section className='pb-[40px] lg:px-[100px] md:px-[50px] px-[30px] mt-[3rem]'>
                <h3 className={`${h3Style}`}>Reviews</h3>
                <div className='flex items-center font-[500] gap-x-7 leading-[28px] lg:text-[20px] text-[18px] rounded-[12px] border-[.8px] p-[20px] w-fit mt-7 border-[#DCDEE2]'>
                    <div className='supreme flex items-center'>
                        <img src={commentIcon} alt="" />&nbsp; {10} reviews
                    </div>
                    <div className='supreme flex items-center'>
                        <img src={reviewIcon} alt="comment" className='h-[25px]' />&nbsp; {90}% positive
                    </div>
                </div>
                <div className='mt-7 border-b border-[#DCDEE2] lg:pb-[100px] pb-[50px]'>{CXM.map((item, i) => (
                    <CommentList key={i} isLast={CXM.length - 1 === i && i} />
                ))}
                    <Stack className='mt-10 lg:float-right'>
                        <Pagination count={10} variant="outlined" shape="rounded" />
                    </Stack>
                </div>
            </section>

            <section className='lg:pb-[70px] pb-[40px] lg:px-[100px] md:px-[50px] px-[30px] mt-[3rem]'>
                <div className='flex items-center justify-between'>
                    <h3 className={`${h3Style}`}>Students who liked this course also liked</h3>
                </div>
                <div className='px-3 mt-[2rem] relative w-full overflow-hidden'>
                    <AliceCarousel
                        mouseTracking
                        responsive={responsive}
                        controlsStrategy="responsive"
                        autoPlay={true}
                        autoPlayInterval={2000}
                        infinite={true}
                        keyboardNavigation={true}
                        disableButtonsControls
                    >
                        {CXM.slice(start, end).map((item, i) => (
                            <CourseCard course={item} key={i} />
                        ))}

                    </AliceCarousel>
                </div>
            </section>
        </LandingPageLayout>
    )
}
