import React from 'react';
import { h2Style } from './style';
import h01 from "../../assets/images/how1.png";
import h02 from "../../assets/images/how4.png";
import h03 from "../../assets/images/how2.png";
import h04 from "../../assets/images/how3.png";

type Props = {}

export default function SectionFive({}: Props) {
  return (
    <section className='py-[70px]'>
        <h1 className={`${h2Style} text-center mb-8`}>How it works</h1>
        <div className='bg-tsa-primaryDark lg:py-[4rem] py-[2.5rem] lg:px-0 px-[2rem]'>
            <div className='grid lg:grid-cols-2 grid-cols-1 justify-center  gap-6 m-auto w-fit'>
                <img src={h01} alt="" className='lg:ml-[3.5rem]' />
                <img src={h02} alt="" />
                <img src={h03} alt="" className='lg:ml-[3.5rem] lg:-mt-[7.3rem]' />
                <img src={h04} alt="" className='' />
            </div>
        </div>
    </section>
  )
}