'use client';
import React, { forwardRef, useEffect, useState } from 'react';
import logoImg from '../../../../assets/images/logo-mini.svg';
import { pStyle } from '../../../waitList/style';
import QRCode from 'qrcode';


interface certProps {
    certificateId: number
}

const Certificate = forwardRef<HTMLDivElement, certProps>(({ certificateId }, ref) => {
    const [src, setSrc] = useState('')
    const generateQRcode = async () => {
        try {
            const result = await QRCode.toDataURL(`${process.env.REACT_APP_CLIENT}/app/courses/certificate/${certificateId}`)
            setSrc(result)
        } catch (error) {
            console.log(error, 'error from qrcode generator')
        }

    }
    useEffect(() => {
        generateQRcode()
    }, [certificateId])
    return (
        <div
            ref={ref}
            className="relative grid gap-3 py-1"
            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
        >
            <img src="/images/certbg.svg" alt="scriipo certificate" className='' />
            <div className='grid gap-2 p-10'>
                <div className='flex items-start justify-between'>
                    <div>
                        <h3 className={`font-bold supreme text-[14px] my-2`}>CERTIFICATE OF COMPLETION</h3>
                        <h1 className={` supreme font-[500] text-[35px] mt-3`}>Mastering Afrobeat Music Production</h1>
                    </div>
                    <img src={logoImg} alt="logo" className='w-[150px]' />
                </div>

                <div className='grid gap-2'>
                    <div className='w-[70%]'>
                        <h3 className={`font-[800] alex-brush-regular text-[30px] italic my-2`}>Victor Asuquo</h3>
                        <hr className='border-gray-500' />
                        <p className={`${pStyle} supreme  text-[12px] mt-3`}>Congratulations on your achievement and dedication to mastering the art of Afrobeat music production. We look forward to seeing your continued growth and success in the music industry.</p>
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                    <div className='grid gap-2 w-[70%] grid-cols-2'>
                        <div className='flex items-center justify-center flex-col'>
                            <h3 className={`supreme text-[16px]  my-2`}>12/03/2024</h3>
                            <hr className='border-gray-500 w-[50%]' />
                            <p className={`${pStyle} supreme  text-[12px] mt-3`}>Date</p>
                        </div>
                        <div className='flex items-center justify-center flex-col'>
                            <h3 className={`font-[800] alex-brush-regular text-[30px] italic my-2`}>Gospel Obi</h3>
                            <hr className='border-gray-500 w-[50%]' />
                            <p className={`${pStyle} supreme  text-[12px] mt-3`}>Signature</p>
                        </div>
                    </div>
                    <div className='flex items-center justify-center flex-col'>
                        <img src={src} />
                        <p className='indie-flower-regular text-[25px] text-tsa-primary'>Scan to verify</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

// Set the displayName for the component
Certificate.displayName = 'Certificate';

export default Certificate;
