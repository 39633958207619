import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import { Link } from 'react-router-dom'
import { btnStyle, h2Style, h3Style, pStyle } from '../../../waitList/style';
import girlImg from "../../../../assets/images/girlImg.png";
import { CXM } from '../../../../utils/constants';
import { CourseCard } from '../../../../components/ui';
function CourseInstructor({ instructorData }: any) {
    return (
        <section>
            <div>
                <h3 className={`${h3Style} supreme text-[20px] my-2`}>Instructor</h3>
                <div className='flex items-center gap-x-4 mt-4'>
                    <img src={instructorData?.user?.image} alt="author" className='lg:h-[80px] lg:w-[80px] h-[60px] w-[60px] rounded-full object-cover' />  <div>
                        <p className='supreme font-[500] text-[20px] leading-[24px] text-[#010516]'>{instructorData?.user?.name}</p>
                        <p className='supreme font-[400] text-[16px] leading-[22px] text-[#555C74] mt-1'>{instructorData?.user?.profession || 'Instructor'}</p>
                    </div>
                </div>
                <div className='flex'>
                    <span className={`${pStyle} supreme mt-3 mb-5`}>
                        {instructorData?.user?.detail}<Link to={`/instructor/${0}`} className='supreme  font-[500] text-[16px] leading-[24px] text-[#010516]'>Read More...</Link>
                    </span>

                </div>

            </div>
            <div>
                <h2 className='supreme text-[20px] my-2'>Other courses by {instructorData?.user?.name}</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6'>
                    {instructorData?.courses?.map((item: any) => (
                        <CourseCard course={item} />
                    ))}
                </div>
            </div>

        </section>

    )
}

export default CourseInstructor