import React from 'react';
import likeIcon from "../../assets/icons/reviewIcon.svg";
import img from "../../assets/images/Image-5.png";

type Props = {
    item?: {
      _author: boolean | string | undefined,
      date: string | undefined,
      _id: string | undefined,
      user: {
        _id: string | undefined,
        fullname: string | undefined,
        image: string | undefined
      },
      message: string
    },
    isLast: boolean | number
}

function CommentList({ item, isLast }: Props) {
  return (
    <div className={`grid gap-x-[3rem] py-3 ${ !isLast && 'border-b border-[#DCDEE2]'}`}>
        <div className='flex items-center gap-x-4 py-1'>
            <img src={item?.user?.image || img} alt="user" className='object-cover lg:h-[56px] lg:w-[56px] h-[40px] w-[40px] rounded-full' />
            <div className='flex gap-x-4 items-start'>
              <div>
                <p className='font-[500] text-[16px] leading-[24px] text-[#010516] supreme'>Chioma vanessa</p>
                <p className='font-[400] text-[14px] leading-[22px] text-[#555C74] supreme mt-1'>7 days ago</p>
              </div>
              <img src={likeIcon} alt="like" className='mt-1' />
            </div>
        </div>
        <p className='py-2.5 font-[400] text-[17px] text-[#010516] leading-[24px] supreme'>{item?.message || "Enrolling in this course was the best investment I've made for my music career. The knowledge and skills I've gained are already helping me in my professional projects. The course has a perfect balance of theory and practical application."}</p>
    </div>
  )
}

export default CommentList;