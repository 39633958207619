import React from 'react';
import { AuthLayout } from '../../../../components/layouts';
import { CustomButton, CustomTextbox } from '../../../../components/ui';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PasswordFieldNoValidation from '../components/PasswordFieldNoValidation';
import { SIGN_IN } from './constant';
import { signup_style } from './style';
import { Link, useNavigate } from 'react-router-dom';
import { LayoutContainer } from '../components';
import { SignInType } from '../../../../hooks/types';
import { useLogin } from '../../../../hooks/react-query/useAuthUser';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

type Props = {}

const initialValues = {
    email: '',
    password: '',
};

const grid = 'grid lg:gap-y-1';

export default function Signin({}: Props) {  
    const { mutate:loginMutation, isLoading } = useLogin();
    const navigate = useNavigate(); 

    const SigninSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const onSubmit = (values: SignInType) => {
        const payload = values;
        loginMutation(payload, {
            onSuccess: (data) => {
                if(!data.success){
                    toast.error(data.error);
                    return;
                }
                toast.success('Login successful!');
                localStorage.setItem('scriipo-token', data?.token)
                navigate('/app/home')
            },
            onError: (error: any) => {
                toast.error(error || error.error || "Login failed!, try again");
                return;
            }
        })
    }


    return (
        <AuthLayout>
            <LayoutContainer className='pb-[50px]'>
                <h1 className={signup_style.h1Style}>{SIGN_IN.HEADING_TEXT}</h1>
                <p className={signup_style.pStyle}>{SIGN_IN.PARAGRAPH_TEXT}</p>

                <Formik 
                onSubmit={onSubmit} 
                validationSchema={SigninSchema} 
                initialValues={initialValues}
                >
                    {({errors, touched}: any) => (
                    <Form className='w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7'>
                        <div className={grid}>
                            <label htmlFor="email" className={signup_style.label}>{SIGN_IN.EMAIL_LABEL}</label>
                            <CustomTextbox
                                id='email'
                                name='email'
                                useFormikForm={true}
                                placeholder={SIGN_IN.EMAIL_PLACEHOLDER}
                            />
                            {errors.email && touched.email ? ( <small className={signup_style.errorStyle}>{errors.email}</small> ) : null}
                        </div>
                        <div className={grid}>
                            <CustomTextbox
                                id='password'
                                name='password'
                                type='password'
                                useFormikForm={true}
                                placeholder={SIGN_IN.PASSWORD_PLACEHOLDER}
                                component={PasswordFieldNoValidation}
                            />
                            {errors.password && touched.password ? ( <small className={signup_style.errorStyle}>{errors.password}</small> ) : null}
                        </div>

                        <CustomButton
                        disabled={isLoading}
                        isLink={false}
                        value={isLoading ? <ClipLoader className='mx-auto' size={20} color='#fff' /> : SIGN_IN.BUTTON_TEXT}
                        className='text-white mt-3 lg:mt-0'
                        />
                        <Link to="/auth/forgot-password" className='supreme -mt-1 font-[500] lg:text-[16px] text-[13px] leading-[24px] text-tsa-primary'>Forgot password?</Link>
                    </Form>
                    )}
                </Formik>
                <p className='mt-10 supreme text-center font-[500] lg:text-[16px] text-[13px] leading-[24px] text-[#555C74]'>Do not have an account? <Link to="/auth/signup" className='supreme text-tsa-primary'>sign up</Link></p>

            </LayoutContainer>
        </AuthLayout>
    )
}
