import user from "../../assets/images/Image-1.png";

type Props = {}

export default function ReviewCard({}: Props) {
  return (
    <div className='relative lg:flex grid max-w-[945px] h-[423px]  mx-auto'>
        <div className='lg:w-2/5 overflow-hidden rounded-tl-[12px] lg:rounded-bl-[12px] lg:rounded-tr-none rounded-tr-[12px]'>
            <img src={user} alt="student" className="object-cover lg:h-full h-[213px] w-full" />
        </div>
        <div className='lg:w-3/5 w-full '></div>
        <div className="absolute z-50 lg:w-[688px] md:w-[70%] w-[80%] lg:h-[60%] my-auto mx-auto lg:top-[30%] left-[10%] right-[10%] lg:bottom-[30%] bottom-0 bg-white lg:py-[50px] py-[30px] lg:px-[30px] px-[15px] lg:-right-[15%]">
            <q className="font-[400] lg:text-[18px] text-[14px] leading-[32px] font-[supreme]">
                I've taken several songwriting courses here, and each one has been fantastic. 
                The instructors are knowledgeable and provide great feedback. The offline access and multi-device 
                compatibility, make learning so convenient. I highly recommend it to anyone looking to improve their 
                songwriting skills.
            </q>
            <p className="font-[400] lg:text-[18px] text-[14px] leading-[28px] font-[supreme] mt-4">Chioma D.</p>
        </div>
    </div>
  )
}