import React from 'react'
import { LandingPageLayout } from '../../components/layouts';
import SectionOne from '../waitList/SectionOne';
import SectionSix from '../waitList/SectionSix';
import SectionFour from './landing/SectionFour';
import SectionFive from './landing/SectionFive';
import SectionTwo from './landing/SectionTwo';
import SectionThree from './landing/SectionThree';
import ReviewSection from './landing/ReviewSection';

type Props = {}

export default function LandingPage({}: Props) {
  return (
    <LandingPageLayout>
      <div className='-mt-[11rem] lg:pt-0 pt-[10rem] pb-[2rem] bg'>
        <SectionOne btnTo="/auth/signin" btnText="Start your journey" />
      </div>
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <ReviewSection />
      <SectionSix />
    </LandingPageLayout>
  )
}