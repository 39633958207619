import { signup_style } from '../style';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CustomButton, CustomTextbox } from '../../../../../components/ui';

type Props = {
    setValues: (arg: FormProps) => void;
    values: {
        firstname: string,
        lastname: string
    }
}
interface FormProps {
    firstname: string;
    lastname: string;
}

const grid = 'grid lg:gap-y-1';


const OnboardingSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is Required'),
    lastname: Yup.string().required('Last name is Required')
  });

export default function UserNameForm({ setValues, values }: Props) {
    const initialValues = {
        firstname: values.firstname || "",
        lastname: values.lastname || ""
    }
    const onSubmit = (values: FormProps ) => {
        setValues(values)
    }

    return (
        <>
            <h1 className={signup_style.h1Style}>Tell us more about yourself</h1>
            <Formik 
            onSubmit={onSubmit} 
            validationSchema={OnboardingSchema} 
            initialValues={initialValues}
            >
            {({errors, touched}) => (
            <Form className='w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7'>
                {/* first name  */}
                <div className={grid}>
                    <label htmlFor="firstname" className={signup_style.label}>First name</label>
                    <CustomTextbox
                        id='firstname'
                        name='firstname'
                        value={initialValues.firstname}
                        useFormikForm={true}
                        placeholder="John"
                    />
                    {errors.firstname && touched.firstname ? ( <small className={signup_style.errorStyle}>{errors.firstname}</small> ) : null}
                </div>
                {/* last name  */}
                <div className={grid}>
                    <label htmlFor="lastname" className={signup_style.label}>Last name</label>
                    <CustomTextbox
                        id='lastname'
                        name='lastname'
                        useFormikForm={true}
                        value={initialValues.lastname}
                        placeholder="Doe"
                    />
                    {errors.lastname && touched.lastname ? ( <small className={signup_style.errorStyle}>{errors.lastname}</small> ) : null}
                </div>

                <CustomButton 
                isLink={false}
                value="Continue"
                className='text-white mt-5'
                />
            </Form>
            )}
            </Formik>
        </>
    )
}