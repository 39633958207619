import React, { useState } from 'react'
import { signup_style } from '../style'
import { SKILL_LEVEL } from '../../../../../utils/constants'
import { Radio } from '@mui/material'
import { CustomButton } from '../../../../../components/ui'

type Props = {
    setValues: (value: string) => void;
    value: string
}

export default function UserLevelPage({ setValues, value }: Props) {
    const [ skillLevel, setSkillLevel ] = useState(value);

    const onClick = () => {
        if(skillLevel){
            setValues(skillLevel)
        }
    }

    return (
        <>
            <h1 className={signup_style.h1Style}>What is your skill level?</h1>
            <div className='w-[83%] mx-auto grid mt-7 gap-y-2'>
                <ul className='overflow-y-auto grid lg:gap-y-4 gap-y-3'>
                {SKILL_LEVEL.map((level, i) => (
                    <li key={i} className='flex items-center py-2.5 px-2 border border-[#DCDEE2] rounded-[6px]'>
                        <Radio 
                            id={level.value} 
                            style={{ color: '#1C7272' }}
                            onChange={() => setSkillLevel(level.value)} 
                            checked={skillLevel === level.value || false}
                            />
                        <label 
                            htmlFor={level.value} 
                            className='supreme hover:cursor-pointer lg:text-[18px] text-[16px] text-[#555C74] font-[500]'>
                                {level.text}
                        </label>
                    </li>
                ))}
                </ul>
                <CustomButton 
                    isLink={false}
                    onClick={onClick}
                    value="Continue"
                    className='text-white mt-5'
                    />
            </div>
        </>
    )
}