import React from 'react'
import { btnStyle, h2Style, pStyle } from './style';
import { available_classes } from '../../utils/constants';
import checkIcon from "../../assets/icons/checks.svg";
import girlImg from "../../assets/images/girlImg.png";
import bgPattern from "../../assets/icons/tilePatternBg.svg";
import { Link } from 'react-router-dom';

type Props = {}

export default function SectionTwo({}: Props) {
  return (
    <section className='lg:pt-[150px] lg:pb-[100px] pt-[50px] pb-[50px]'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-[7rem] items-end'>
            <div className='col-span-1 lg:pl-[100px] pl-[20px] lg:pr-0 pr-[20px]'>
                <h2 className={`${h2Style} mb-4`}>Gain access to extensive course categories.</h2>
                <p className={`${pStyle} mb-5`}>Enjoy offline downloads, learn from any devices, and explore new <br /> classes added every month, all taught by industry experts.</p>
                <ul className='grid gap-3'>{available_classes.map((item, i)=>
                 (
                    <li key={i} className='flex items-center gap-3'>
                        <img src={checkIcon} alt="check" />
                        <p className={`${pStyle}`}>{item}</p>
                    </li>
                ))}
                </ul>
                <div className='mt-[3rem]'>
                    <Link className={`${btnStyle}`} to="/waitlist/join">Join the waitlist</Link>
                </div>
            </div>
            <div className='col-span-1  lg:pr-[100px] pr-[20px] lg:pl-0 pl-[20px]'>
                <div className='background-img_'>
                    <img src={girlImg} alt="girl_listening_to_music" className='mx-auto w-[75%]' />
                </div>
            </div>
        </div>
    </section>
  )
}