import React from 'react'
import { LandingPageLayout } from '../../../components/layouts';
import { h1Style, h3Style } from '../../waitList/style';
import { PRIVACY_POLICY } from '../../../utils/constants';
import privacy_header_img from "../../../assets/images/privacy_header_img.png";

type Props = {}

export default function PrivacyPolicy({}: Props) {
    return (
        <LandingPageLayout hideSection={true}>
             <div className='-mt-[10.7rem] lg:h-[95vh] pt-[150px] h-[95vh] bg'>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-9 items-center">
                    <div className='w-[75%] mx-auto'>
                        <h1 className={`${h1Style} py-5 text-center lg:text-left`}>Privacy policy</h1>
                        <p className='font-[supreme] text-[17px] text-[#555C74] text-center lg:text-left'>{PRIVACY_POLICY.MAIN}</p>
                        <p className='font-[supreme] text-[17px] text-[#010516] mt-10 text-center lg:text-left'>Published on {PRIVACY_POLICY.PIBLISH_DATE}</p>
                    </div>
                    <div className='flex justify-end'>
                        <img src={privacy_header_img} alt="privacy_img" />
                    </div>
                </div>
            </div>
            <section className='lg:py-[100px] lg:px-[60px] py-[20px] px-[20px] privacy-policy'>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Information we collect</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.INFO_WE_COLLECT}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>How we use your information</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.HOW_WE_USE_INFO}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Sharing your information</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.SHARING_YOUR_INFO}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Data security</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.DATA_SECURITY}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Your rights</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.YOUR_RIGHTS}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Cookies</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.COOKIES}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Changes to our privacy policy</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{PRIVACY_POLICY.CHANGES_TO_OUR_POLICY}</p>
                </div>
            </section>
        </LandingPageLayout>
    )
}