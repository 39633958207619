import { useState } from 'react';
import logo from "../../assets/images/logo-mini.svg";
import gospel from "../../assets/images/gospel.png";
import { Link, useNavigate } from 'react-router-dom';
import { useJoinwaitlist } from '../../hooks/react-query/useWaitlist';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';


const btnStyle = "w-full grid text-center bg-tsa-primary text-white font-['supreme'] rounded-[6px] py-[14px] text-[16px] mt-5 font-[600]";
const inputStyle = "w-full font-['supreme'] border border-tsa-borderInput px-[16px] py-[10px] rounded-[6px]";
const labelStyle = "text-[16px] font-[500] font-['supreme']";
const grid = "grid gap-y-1";
const formStyle = 'grid gap-y-3.5';
const pStyle = "font-[400] text-[18px] leading-[24px] text-[#555C74] font-['supreme'] mb-5";
const h1Style = "font-[700] text-[25px] leading-[38px] mt-6 mb-1 font-[technorSemibold]"


const initialData = {
    firstname: "",
    lastname: "",
    email: "",
    area: ""
}

export default function WaitListForm() {
    const [ formData, setFormData ] = useState(initialData);

    const navigate = useNavigate();
    const { mutate: waitlistMutation, isLoading } = useJoinwaitlist()

    const handleSubmit = (e: any) => {
        if(isLoading) return false;
        e.preventDefault();

        const toastId = "waitlist";
        waitlistMutation(formData, {
            onSuccess: () => {
                navigate('/waitlist');
                toast.success('Added to waitlist', { toastId });     
            },
            onError: (error: unknown | any) => {
                toast.error(error || error?.message || "Unable to complete action", { toastId })
            }
        })
    }

  


    return (
        <div className='bg h-[100vh] w-full'>
            <div className='w-[90%] lg:h-[87vh] h-[75vh] relative top-[7%] mx-auto grid lg:grid-cols-5 grid-cols-1'>
                <div className='lg:col-span-2 lg:block hidden overflow-hidden rounded-l-[20px]'>
                    <img src={gospel} alt="gospel_on_de_beat" className='h-full w-full object-cover' />
                </div>
                <div className='lg:col-span-3 col-span-1 bg-white rounded-l-[20px] lg:rounded-l-[0px] rounded-r-[20px]'>
                    <div className='lg:w-[60%] w-[90%] mx-auto lg:mt-[7.5%] mt-[12%]'>
                        <Link to="/waitlist">
                            <img src={logo} alt="logo" className='mx-auto' />
                        </Link>
                        <h1 className={h1Style}>Join our waitlist</h1>
                        <p className={pStyle}>Complete the form below to join the waitlist.</p>

                        <form className={formStyle} onSubmit={handleSubmit}>
                            <div className={grid}>
                                <label className={labelStyle} htmlFor="firstName">First name</label>
                                <input 
                                    type="text" 
                                    id='firstName' 
                                    required
                                    placeholder='e.g John' 
                                    onChange={(e) => setFormData({...formData, firstname: e.target.value })}
                                    className={inputStyle}
                                />
                            </div>
                            <div className={grid}>
                                <label className={labelStyle} htmlFor="lastName">Last name</label>
                                <input 
                                    type="text" 
                                    id='lastName' 
                                    placeholder='e.g Doe' 
                                    onChange={(e) => setFormData({...formData, lastname: e.target.value })}
                                    className={inputStyle}
                                />
                            </div>
                            <div className={grid}>
                                <label className={labelStyle} htmlFor="email">Email address</label>
                                <input 
                                    type="email" 
                                    id='email' 
                                    required
                                    placeholder='e.g example@gmail.com' 
                                    onChange={(e) => setFormData({...formData, email: e.target.value })}
                                    className={inputStyle}
                                />
                            </div>
                            <div className={grid}>
                                <label className={labelStyle} htmlFor="interest">Area of interest</label>
                                <input 
                                    type="text" 
                                    id='interest' 
                                    required
                                    placeholder='e.g example@gmail.com' 
                                    onChange={(e) => setFormData({...formData, area: e.target.value })}
                                    className={inputStyle}
                                />
                            </div>

                            <button disabled={isLoading} className={btnStyle} type='submit'>
                            {isLoading ? 
                            <ClipLoader className='mx-auto' size={20} color='#fff' /> : 
                            "Join the waitlist"
                            }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}