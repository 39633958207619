import React, { useState } from 'react';
import { h2Style, pStyle } from '../../waitList/style';
import { CXM, } from '../../../utils/constants';
import AliceCarousel from 'react-alice-carousel';
import { InstructorCard } from '../../../components/ui';
import previous from '../../../assets/icons/slider-left.svg';
import next from '../../../assets/icons/slider-right.svg';
import { Link } from 'react-router-dom';
import arrRight from '../../../assets/icons/arrRight.svg';

type Props = {};

const responsive = {
    0: { items: 1 },
    450: { items: 2 },
    800: { items: 3 },
    1024: { items: 4 },
}

export default function SectionThree({ }: Props) {
    const [activeTab, setActiveTab] = useState('Artist development')

    return (
        <section className='bg-white lg:py-[100px] py-[50px]'>
            <div className='lg:px-[100px] px-[10px]'>
                <h2 className={`${h2Style} text-center mb-2`}>Get to know your instructors.</h2>
                <p className={`${pStyle} text-center lg:w-[62%] w-[90%] mx-auto`}>
                    Meet the industry's best instructors who are committed to your success, providing you with timely and engaging content.
                </p>
            </div>

            <div className='lg:px-[100px] px-3 mt-[4rem] relative w-full overflow-hidden'>
                <AliceCarousel
                    mouseTracking
                    responsive={responsive}
                    controlsStrategy="responsive"
                    autoPlay={true}
                    autoPlayInterval={2000}
                    infinite={true}
                    keyboardNavigation={true}
                    renderPrevButton={() => {
                        return <img src={previous} className="absolute md:-left-[4.5rem] md:block hidden top-[10rem] " alt='previous' />
                    }}
                    renderNextButton={() => {
                        return <img src={next} className="absolute md:-right-[2.3rem] md:block hidden top-[10rem] hover:cursor-pointer" alt='next' />
                    }}
                >
                    {CXM.map((item) => (
                        <InstructorCard instructor={item} />
                    ))}

                </AliceCarousel>
            </div>
            <Link to="/all-instructors" className={`mt-10 font-[500] text-[16px] leading-[24px] text-[#010516] font-[supreme] text-center flex gap-x-2 items-center justify-center`} >
                See all instructors <img src={arrRight} alt="arr-right" />
            </Link>
        </section>
    )
}