import { useQuery, useMutation, QueryClient } from "react-query";
import ReviewService from "../../services/review.service";

const queryClient = new QueryClient();
const reviewService = new ReviewService();

export const useGetReview = (id: string) => {
  return useQuery(["review", id], () => reviewService.getReview(id));
};

export const usePostReview = () => {
  return useMutation(
    ({
      id,
      payload,
    }: {
      id: string;
      payload: { rating: number; body: string; positive: boolean };
    }) => reviewService.postReview(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reviews");
      },
    }
  );
};
