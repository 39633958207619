import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode,
    className?: string
}

export default function LayoutContainer({ children, className }: Props) {
    return (
        <div className={`lg:w-[40%] w-[90%] mt-[70px] bg-white mx-auto rounded-[20px] pt-[50px] relative ${className}`}>
            {children}
        </div>
    )
}