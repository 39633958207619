import { useEffect, useState } from 'react';
import { AuthLayout } from '../../../../components/layouts';
import LayoutContainer from './LayoutContainer';
import { signup_style } from '../student/style';
import { useVerifyEmail } from '../../../../hooks/react-query/useAuthUser';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { CustomButton } from '../../../../components/ui';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { setUser } from '../../../../redux/slice/userSlice';

type Props = {}

export default function EmailVerfication({}: Props) {
    const { 
        mutate: verifyEmailMutate, 
        isLoading,
        isSuccess,
    } = useVerifyEmail();
    const { id, token } = useParams();
    const dispatch = useDispatch();
    const [ isVerified, setIsVerified ] = useState(false)

    

    useEffect(() => {
        if(id && token){
            const url = "/authentication/verify"
            const payload = { token }
            verifyEmailMutate({ url, payload, id }, {
                onSuccess: (data) => {
                    if(!data.success){
                        toast.error(data.error);
                        return
                    }
                    dispatch(setUser(data?.user))
                    setIsVerified(data?.user?._email)
                    localStorage.setItem('scriipo-token', data?.token)
                }
            })
        }
    }, [id, token])

    return (
        <AuthLayout>
            {isLoading ? 
            <ClipLoader className='relative left-[45%] top-[13rem]' size={100} color='#1C7272' /> : 

            <LayoutContainer className='pb-[50px] mt-[14rem]'>
                <h1 className={signup_style.h1Style}>{isSuccess ? "Verification Successful!" : "Verification Failed!"}</h1>

                {isSuccess && 
                <p className={`${signup_style.pStyle} lg:w-[70%] w-[80%] mx-auto py-1`}>
                    Congratulations, Your email has been verified.
                </p>}

                <div className='w-[70%] mx-auto mt-5'>
                    {isSuccess ?
                        <CustomButton 
                            isLink={true}
                            to={isVerified ? '/app/home' : '/onboarding'}
                            value="Continue"
                            className='text-white flex justify-center'
                            /> :
                        <CustomButton 
                            isLink={true}
                            to={isVerified ? '/auth/signup' : '/onboarding'}
                            value="Restart authentication process"
                            className='text-white flex justify-center'
                            />
                    }
                </div>
            </LayoutContainer>
            }

        </AuthLayout>
    )
}