import React from 'react'
import { LandingPageLayout } from '../../../components/layouts'
import { h1Style } from '../../waitList/style'
import SectionSix from '../../waitList/SectionSix'
import { SearchBox } from '../../../components/ui'

type Props = {}

export default function Help({}: Props) {
    const handleSearch = () => {}
    return (
        <LandingPageLayout>
            <div className='-mt-[10.7rem] lg:h-[70vh] pt-[230px] h-[60vh] bg'>
                <h1 className={`${h1Style} text-center`}>How can we help?</h1>
                <div className='lg:w-[42%] w-[70%] mx-auto mt-10'>
                    <SearchBox handleSearch={handleSearch} placeholder='Search' />
                </div>
            </div>
            <SectionSix />
        </LandingPageLayout>
    )
}