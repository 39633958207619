import React from 'react';
import logo from "../../assets/images/logo-mini.svg";
import hamburgerIcon from "../../assets/icons/hamburger.svg"
import { Link } from 'react-router-dom';

type Props = {}

export default function Nav({}: Props) {
  return (
    <nav className='w-full lg:h-[70px] h-[50px] flex items-center bg-transparent relative lg:top-2 top-3'>
        <div className='lg:w-[85%] w-[97%] mx-auto bg-white rounded-[20px] h-full flex items-center justify-between px-[20px] py-[15px]'>
            <img src={logo} alt="logo" className='lg:w-fit w-[30%]' />
            <div >
                <Link className="bg-tsa-primary p-[12px] lg:block hidden rounded-[8px] font-[supreme] text-white text-[14px] tracking-wide" to="/waitlist/join">Join the waitlist</Link>
                <img src={hamburgerIcon} alt="collapse icon" className='lg:hidden block' />
            </div>
        </div>
    </nav>
  )
}