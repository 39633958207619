import { request } from "../hooks/api";
import { WaitlistFormType } from "../hooks/types";

class WaitlistService {
    async joinWaitlist(payload: WaitlistFormType) {
        try {
            const response = await request({
                url: `/waitlist`,
                method: 'POST',
                data: payload
            }, false);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default WaitlistService;
