import React from 'react';
import checkIcon from "../../../assets/icons/checks.svg";
import girlImg from "../../../assets/images/girlImg.png";
// import bgPattern from "../../.assets/icons/tilePatternBg.svg";
import { Link } from 'react-router-dom';
import { btnStyle, h2Style, pStyle } from '../../waitList/style';

type Props = {}

export default function SectionFive({}: Props) {
  return (
    <section className='lg:pt-[150px] lg:pb-[100px] pt-[50px] pb-[50px]'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-[7rem] items-center'>
            <div className='col-span-1 lg:pl-[100px] pl-[50px] lg:pr-0 pr-[50px]'>
                <h2 className={`${h2Style} mb-4`}>Gain access to extensive course categories.</h2>
                <p className={`${pStyle} mb-5`}>Enjoy offline downloads, learn from any devices, and explore new <br /> classes added every month, all taught by industry experts. <br />Starting from $10</p>
                <div className='mt-[3rem]'>
                    <Link className={`${btnStyle}`} to="/">Learn more</Link>
                </div>
            </div>
            <div className='col-span-1  lg:pr-[100px] pr-[20px] lg:pl-0 pl-[20px]'>
                <div className='background-img_'>
                    <img src={girlImg} alt="girl_listening_to_music" className='mx-auto w-[75%]' />
                </div>
            </div>
        </div>
    </section>
  )
}