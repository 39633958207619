import React from 'react'
import { Link } from 'react-router-dom';
import { SOCIAL_LINKS } from '../../utils/constants';
import logo from "../../assets/images/logo-footer.svg";

type Props = {}

export default function Footer({}: Props) {
    return (
        <footer className='pt-[70px] bg-tsa-primary px-[2rem]'>
            <div className='flex flex-wrap flex-row pb-[6rem] gap-y-8'>
                <div className="lg:w-[40%] w-full">
                    <img src={logo} alt="logo" />
                </div>
                <div className="lg:w-[60%] w-full grid gap-y-4">
                    <p className='font-[supreme] text-white text-[16px] font-[500] leading-[24px]'>Connect with us</p>
                    <ul className="flex flex-wrap flex-row gap-5">
                        {SOCIAL_LINKS.map((item, i) => (
                            <li key={i}>
                                <Link to={item.link} className='flex items-start gap-2'>
                                    <img src={require(`../../assets/icons/${item.img}`)} alt={item.name} />
                                    <p className='font-[supreme] text-[#DCDEE2] lg:block hidden'>{item.name}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='pb-[4rem] pt-[2rem] border-t-[.5px] border-[#DCDEE2]'>
                <p className='font-[supreme] text-[#DCDEE2]'>© 2024 SCRiiPO. All Rights Reserved.</p>
            </div>
        </footer>
    )
}