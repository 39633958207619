import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import accordionIcon from '../../assets/icons/accordionIcon.svg';
import { FAQ } from '../../utils/constants';
import { h2Style } from './style';

export default function SectionSix() {
  return (
    <section className='py-[70px]'>
        <h2 className={`${h2Style} text-center`}>Frequently<br/><span className='text-tsa-primary'>Asked questions</span></h2>
        <div className='lg:w-[85%] w-[95%] mx-auto mt-[40px]'>
            {FAQ.map((item, i) => (
            <Accordion key={i}>
                <AccordionSummary
                expandIcon={<img src={accordionIcon} alt="plus" />}
                id={`${item.id}`}
                >
                    <Typography>{item.header}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{item.content}</Typography>
                </AccordionDetails>
            </Accordion>
            ))}
        </div>
    </section>
  );
}
