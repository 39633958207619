import React from 'react'
import { signup_style } from './style';
import { CustomButton, CustomTextbox } from '../../../../components/ui';
import { LayoutContainer } from '../components';
import { AuthLayout } from '../../../../components/layouts';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {  Link } from 'react-router-dom';
import { SIGN_IN } from './constant';

type Props = {}
interface FormProps {
    email: string;
}

const initialValues = {
    email: "",
}

const grid = 'grid lg:gap-y-1';

    
const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
});

export default function ForgotPassword({}: Props) {
    const onSubmit = () => {};

    return (
        <AuthLayout >
            <LayoutContainer className='pb-[50px] mt-[10rem]'>
                <h1 className={signup_style.h1Style}>Lets get you back in!</h1>
                <p className={`${signup_style.pStyle} mx-auto py-1.5`}>Provide the email associated with this account</p>

                <Formik 
                onSubmit={onSubmit} 
                validationSchema={SignupSchema} 
                initialValues={initialValues}
                >
                {({errors, touched}) => (
                <Form className='w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7'>
                    <div className={grid}>
                        <label htmlFor="email" className={signup_style.label}>{SIGN_IN.EMAIL_LABEL}</label>
                        <CustomTextbox
                            id='email'
                            name='email'
                            useFormikForm={true}
                            placeholder={SIGN_IN.EMAIL_PLACEHOLDER}
                        />
                        {errors.email && touched.email ? ( <small className={signup_style.errorStyle}>{errors.email}</small> ) : null}
                    </div>

                    <CustomButton 
                    isLink={false}
                    value="Continue"
                    className='text-white mt-2'
                    />
                </Form>
                )}
                </Formik>
                <div className='w-fit mx-auto mt-2'>
                    <Link to="/auth/signin" className='supreme mt-1 font-[500] text-center lg:text-[16px] text-[13px] leading-[24px] text-tsa-primary'>Back to login</Link>
                </div>
            </LayoutContainer>

        </AuthLayout>
    )
}