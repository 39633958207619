import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import logoMobile from '../../../../assets/icons/scriipo-logo-mobile.svg';
import PasswordField from '../components/PasswordField';
import { LandingPageLayout } from '../../../../components/layouts';
import { Link } from 'react-router-dom';
import { CountryDropdown } from '../../../../components/ui';


// FORM VALIDATION
const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const initialValues = {
    email: '',
    fullName: '',
    country: '',
    courseTitle: '',
    phone: '',
    courseDetail: ''
};
const SignUpSchema = Yup.object({
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    fullName: Yup.string().required("Full name is required"),
    country: Yup.string().required("Country is required"),
    course: Yup.string().required("Course title is required"),
    detail: Yup.string().required("Course detail is required"),
})


const inputContainerStyle = "w-full border text-[12px] mt-1.5 outline-none rounded-[6px] border-tsa-background bg-tsa-background text-tsa-inputText py-[16px] pl-3 pr-6";
const inputStyle = "w-full outline-none text-[12px] leading-[14px] text-tsa-inputText bg-transparent";
const labelStyle = "text-[14px] font-[400] text-tsa-textGrey py-1";
const errorStyling = "text-[12px] leading-[14px] text-red-700";
const h1Style = 'font-[700] lg:text-[30px] text-[20px] leading-[43px] mt-4 text-tsa-textGrey';
const pStyle = 'font-[400] lg:text-[16px] text-[14px] leading-[21px] text-tsa-textGrey/[.6]';
const btnStyle = 'w-full bg-tsa-primary text-white rounded-[6px] py-[14px] text-[16px]';




export default function ContributorSignUp() {

    const handleCountryChange = () => {}

    const handleSignUp = () => {};

    return (
        <LandingPageLayout>
          <div className='bg-tsa-background h-fit py-[3rem]'>
            <div className='lg:w-[38%] w-[95%] mx-auto bg-tsa-backgroundLight lg:px-[4rem] px-[2rem] pt-[3rem] pb-[1rem] rounded-[5px]'>
                <img src={logoMobile} alt="logo" />
                <h1 className={h1Style}>Become an Instructor</h1>
                <p className={pStyle}>One step to get onboarded</p>

                <Formik
                    initialValues={initialValues}
                    validationSchema={SignUpSchema}
                    onSubmit={handleSignUp}
                >
                    {({ errors, touched, values }) => (
                        <Form className="py-8 flex flex-col gap-6">
                            {/* fullName  */}
                            <div>
                                <label className={labelStyle}>Full Name</label>
                                <Field 
                                    id="fullName"
                                    name="fullName"
                                    placeholder="Enter full name"
                                    type="text"
                                    className={inputContainerStyle}
                                />
                                {errors.fullName && touched.fullName && <p className={errorStyling}>{errors.fullName}</p>}
                            </div>

                            {/* email  */}
                            <div>
                                <label className={labelStyle}>Email</label>
                                <Field 
                                    id="email"
                                    name="email"
                                    placeholder="Enter email address"
                                    type="email"
                                    className={inputContainerStyle}
                                />
                                {errors.email && touched.email && <p className={errorStyling}>{errors.email}</p>}
                            </div>

                            {/* country  */}
                            <div>  
                              <label className={labelStyle}>Country/Region</label>
                              <CountryDropdown handleCountryChange={handleCountryChange} />
                              {errors.country && touched.country && <p className={errorStyling}>{errors.country}</p>}
                            </div>


                            {/* phone  */}
                            <div>
                              <label className={labelStyle}>Phone Number</label>
                              <Field 
                                  id="phone"
                                  name="phone"
                                  placeholder="Enter Phone Number"
                                  type="tel"
                                  className={inputContainerStyle}
                              />
                              {errors.phone && touched.phone && <p className={errorStyling}>{errors.phone}</p>}
                            </div>

                            {/* Course Title  */}
                            <div>
                              <label className={labelStyle}>Proposed Course</label>
                              <Field 
                                  id="courseTitle"
                                  name="courseTitle"
                                  placeholder="Enter Proposed Course Title"
                                  type="text"
                                  className={inputContainerStyle}
                              />
                              {errors.courseTitle && touched.courseTitle && <p className={errorStyling}>{errors.courseTitle}</p>}
                            </div>

                            {/* Course Detail  */}
                            <div>
                              <label className={labelStyle}>Detail Breakdown</label>
                              <Field 
                                  id="courseDetail"
                                  name="courseDetail"
                                  placeholder="Enter Course Detail/Breakdown"
                                  as="textarea"
                                  rows={7}
                                  className={`${inputContainerStyle} resize-none`}
                              />
                              {errors.courseDetail && touched.courseDetail && <p className={errorStyling}>{errors.courseDetail}</p>}
                            </div>

                            {/* gender  */}
                            {/* <div>
                                <label className={labelStyle}>Gender</label>
                                <div className={inputContainerStyle}>
                                    <Field
                                        as="select"
                                        id="gender"
                                        name="gender"
                                        className={inputStyle}
                                    >
                                        <option value="" disabled selected hidden>
                                        Select Gender
                                        </option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </Field>
                                </div>
                            </div> */}
                            

                            {/* password  */}
                            {/* <Field 
                                id="password" 
                                name="password" 
                                component={PasswordField} 
                            />
                              <p className="lg:text-[14px] text-[12px] -mt-3 text-tsa-textGrey/[0.6]">
                                Your password must be at least 8 characters, and can’t begin or end with a space
                            </p> */}

                            <button 
                                type='submit' 
                                className={btnStyle}>
                                    Submit
                            </button>

                            <p className='text-[14px] text-tsa-textGrey/[.6] mt-3'>By proceeding, you agreed to the <a href="/" className='text-tsa-primary'>Terms and Conditions</a> and <a href="/" className='text-tsa-primary'>privacy Policy.</a></p>
                        </Form>
                    )}
                </Formik>
            </div>
          </div>
        </LandingPageLayout>
    )
}
