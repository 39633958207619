import ResumeLearning from './resumeLearning';
import { useNavigate } from 'react-router-dom';



function InProgess({ Courses }: any) {
    const navigate = useNavigate();

    return (
        <div className="mt-5 w-full grid gap-2 pb-5">
            {Courses?.recommended.map((el: any) => (<ResumeLearning inProgess={true} course={el} />))}
        </div>
    );
}

export default InProgess;
