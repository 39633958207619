import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import {
    ContributorSignUp,
    CountrySelectPage,
    Signup,
    Signin,
    ForgotPassword,
    ResetPassword,
    Onboarding
} from "../pages/webapp/auth";

import NotificationPage from "../components/ui/NotificationPage";
import WaitList from "../pages/waitList";
import WaitListForm from "../pages/waitList/WaitlistForm";
import LandingPage from "../pages/landingPage";
import Pricing from "../pages/landingPage/pricing";
import Courses from "../pages/landingPage/courses";
import Instructors from "../pages/landingPage/instructors";
import InstructorDetail from "../pages/landingPage/instructor-detail";
import Help from "../pages/landingPage/help";
import Search from "../pages/landingPage/help/search";
import PrivacyPolicy from "../pages/landingPage/privacy-policy";
import TermsOfUse from "../pages/landingPage/terms";
import About from "../pages/landingPage/about";
import CourseDetail from "../pages/landingPage/course-detail";
import Home from "../pages/webapp/app/home";
import MyLearning from "../pages/webapp/app/my-learning";
import Account from "../pages/webapp/app/account";
import MyCourses from "../pages/webapp/app/courses";
import SingleCourse from "../pages/webapp/app/courses/SingleCourse";
import CourseCertificate from "../pages/webapp/app/courses/CourseCertificate";
import CourseEnrollment from "../pages/webapp/app/courses/CourseEnrollment";
import ProtectedRoute from "./ProtectedRoute";
import { EmailVerfication, SignupSuccess } from "../pages/webapp/auth/components";


const routes: RouteObject[] = [
    { path: '/', element: <Navigate to="/home" replace={true} /> },
    { path: '/home', element: <LandingPage /> },
    { path: '/pricing', element: <Pricing /> },

    /////////////////    Utility pages   //////////////////
    { path: '/success', element: <NotificationPage /> },
    { path: '/all-courses', element: <Courses /> },
    { path: '/all-courses/:id', element: <CourseDetail /> },
    { path: '/all-instructors', element: <Instructors /> },
    { path: '/all-instructors/instructor/:id', element: <InstructorDetail /> },
    { path: '/waitlist', element: <WaitList /> },
    { path: '/waitlist/join', element: <WaitListForm /> },
    { path: '/help', element: <Help /> },
    { path: '/help/search', element: <Search /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-use', element: <TermsOfUse /> },
    { path: '/about-us', element: <About /> },
    { path: '/email-verification/:id/:token', element: <EmailVerfication /> },

    { path: '/auth/signup', element: <Signup /> },
    { path: '/auth/signin', element: <Signin /> },
    { path: '/auth/success', element: <SignupSuccess /> },
    { path: '/auth/forgot-password', element: <ForgotPassword /> },
    { path: '/auth/reset-password', element: <ResetPassword /> },
    { path: '/onboarding', element: <Onboarding /> },
    // { path: '/app/home', element: <Home /> },
    // { path: '/app/my-learning', element: <MyLearning /> },
    // { path: '/app/account', element: <Account /> },
    // { path: '/app/courses', element: <MyCourses /> },
    // { path: '/app/courses/:cid/:lid', element: <SingleCourse /> },
    // { path: '/app/courses/certificate', element: <CourseCertificate /> },
    // { path: '/app/enrollment/:id', element: <CourseEnrollment /> },
    {
        path: '/app',
        element: <ProtectedRoute />,
        children: [
            { path: 'home', element: <Home /> },
            { path: 'my-learning', element: <MyLearning /> },
            { path: 'account', element: <Account /> },
            { path: 'courses', element: <MyCourses /> },
            { path: 'courses/:cid/:lid', element: <SingleCourse /> },
            { path: 'courses/certificate', element: <CourseCertificate /> },
            { path: 'enrollment/:id', element: <CourseEnrollment /> },
        ],
    },

];

export { routes };
