
import logo from '../../assets/images/logo-mini.svg';
import successImg from "../../assets/images/successImg.svg";
import { Link } from 'react-router-dom';


const h1Style = "font-[technorSemibold] text-center lg:text-[30px] text-[18px] lg:leading-[37px] leading-[28px] mt-4 text-tsa-textBlack";
const pStyle = 'font-[400] text-center lg:text-[30px] text-[16px] leading-[21px] text-tsa-textGrey mt-[3rem]';
const btnStyle = 'w-full grid text-center bg-tsa-primary text-white rounded-[6px] py-[14px] text-[16px] lg:mt-[4rem] mt-[2.5rem]';



export default function NotificationPage() {
  return (
    <div className='h-[100vh] w-full bg'>
      <div className=' lg:w-[35%] w-[90%] m-auto bg-white relative lg:top-[9%] top-[16%] lg:px-[4rem] px-[2rem] pt-[4rem] lg:pb-[5rem] pb-[4rem] rounded-[20px]'>
          <img src={logo} alt="scriipo logo" className='mx-auto' />
          <img src={successImg} alt="success img" className='mx-auto mt-[3rem]' />
          <h1 className={pStyle}>Success!</h1>
          <p className={h1Style}>You’ve been added <br />to the waitlist</p>
          
          <Link to="/auth/signin" className={btnStyle}>Back to home</Link>
      </div>
    </div>
  )
}
