import React, { useState } from 'react';
import { h2Style, pStyle } from '../../waitList/style';
import { COURSES, CXM, } from '../../../utils/constants';
import AliceCarousel from 'react-alice-carousel';
import { CourseCard } from '../../../components/ui';
import previous from '../../../assets/icons/slider-left.svg';
import next from '../../../assets/icons/slider-right.svg';
import { Link } from 'react-router-dom';
import arrRight from '../../../assets/icons/arrRight.svg';

type Props = {};

const responsive = {
    0: { items: 1 },
    450: { items: 2 },
    800: { items: 3 },
    1024: { items: 4 },
}

export default function SectionTwo({ }: Props) {
    const [activeTab, setActiveTab] = useState('Artist development')

    return (
        <section className='bg-white lg:py-[100px] py-[50px]'>
            <div className='lg:px-[100px]  px-[10px]'>
                <h2 className={`${h2Style} text-center`}>Explore our courses.</h2>
                <p className={`${pStyle} text-center`}>Find the ideal course for your skill level and interests, and learn from the best.</p>
            </div>
            <ul className='lg:max-w-[720px] w-auto min-w-[300px] mx-auto flex overflow-x-auto justify-between mt-5 gap-y-3 gap-x-3'>
                {COURSES.map((item, i) => (
                    <li 
                    onClick={() => setActiveTab(item)} 
                    key={i} 
                    className={`text-center text-nowrap w-fit font-[supreme] px-3.5 py-1.5 
                        ${item === activeTab ?
                        'bg-tsa-primary text-white' : 'bg-[#F1F2F3] text-[#AAACB1]'} hover:cursor-pointer text-[12px] rounded-[10px]`}
                        >{item}</li>
                ))}
            </ul>

            <div className='lg:px-[100px]  px-3 mt-[4rem] relative w-full overflow-hidden'>
                <AliceCarousel
                    mouseTracking
                    responsive={responsive}
                    controlsStrategy="responsive"
                    autoPlay={true}
                    autoPlayInterval={2000}
                    infinite={true}
                    keyboardNavigation={true}
                    renderPrevButton={() => {
                        return <img src={previous} className="absolute md:-left-[4.5rem] md:block hidden top-[10rem] " alt='previous' />
                    }}
                    renderNextButton={() => {
                        return <img src={next} className="absolute md:-right-[2.3rem] md:block hidden top-[10rem] hover:cursor-pointer" alt='next' />
                    }}
                >
                    {CXM.map((item) => (
                        <CourseCard course={item} />
                    ))}

                </AliceCarousel>
            </div>
            <Link to="/all-courses" className={`mt-10 font-[500] text-[16px] leading-[24px] text-[#010516] font-[supreme] text-center flex gap-x-2 items-center justify-center`} >
                See all courses <img src={arrRight} alt="arr-right" />
            </Link>
        </section>
    )
}