
// redux/store.ts
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './slice/userSlice';

// Import your reducers here
// import myReducer from './myReducer';

const rootReducer: any = combineReducers({
  user: userSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;