import React from 'react';
import backArrow from '../../../../../assets/icons/backArrow.svg';

type Props = {
    percentage: number;
    handlePrevious: () => void;
}

export default function OnboardingProgressbar({ handlePrevious, percentage }: Props) {
  return (
    <div className='w-[94%] mx-auto flex items-center gap-x-5 mt-[2rem]'>
        {percentage > 30 && percentage <= 90 && <img src={backArrow} alt="back_arrow" onClick={handlePrevious} className='hover:cursor-pointer' />}
        <div className='w-full h-[8px] rounded-[20px] bg-[#F1F2F3]'>
            <div  
              className={`h-full rounded-[20px] bg-tsa-primary delay-100`} 
              style={{
                width: `${percentage}%`,
                transition: 'width 0.5s ease-in-out'
              }}></div>
        </div>
    </div>
  )
}