
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

function CourseContent({ data }: any) {

    return (
        <div>
            {data?.data?.group?.map((el: any) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<MdKeyboardArrowDown />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <div>
                            <h4 className='text-[14px] font-bold supreme'>Lesson {el.position}: {el.title}</h4>
                            <p className='text-[12px] supreme'>7 lectures | 13mins</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetail group={el} data={data} />
                </Accordion>
            ))

            }

        </div>
    )
}

const AccordionDetail = ({ group, data }: any) => {
    const filteredLesson = data.data.lessons.filter((e: any) => e.group === group._id)
    const sortedLessons = [...filteredLesson].sort((a, b) => a.position - b.position);
    return <AccordionDetails>
        {
            sortedLessons.map((lesson: any) => (<div className="h-max w-full p-3 py-3  flex flex-col gap-1  justify-between text-gray-600 hover:bg-green-100  text-[10px] font-medium ">
                <div className="flex items-center justify-between w-full">
                    <div className="flex gap-2 items-center ">
                        <FaCheckCircle size={18} className="text-tsa-primary" />
                        <p className="text-gray-700 text-[13px]">{lesson.title}</p>
                    </div>
                </div>
                10 mins
            </div>))
        }
    </AccordionDetails >
}

export default CourseContent