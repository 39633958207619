import React from "react";
import { AppLayout } from "../../../../components/layouts";
import { Tab, Tabs } from "../../../../components/ui/Tab";
import PersonalDetails from "./PersonalDetails";
import PasswordSettings from "./PasswordSettings";
import Subscription from "./Subscription";
import Certification from "./Certification";
import { useGetProfile } from "../../../../hooks/react-query/useAuth";
import Loader from "../../../../components/ui/Loader";

type Props = {};

export default function Account({ }: Props) {
  const { isLoading, data, refetch } = useGetProfile();
  if (isLoading) {
    return <Loader />;
  }
  return (
    <AppLayout>
      <div className="my-[70px] mx-[20px] w-[80%]">
        <Tabs>
          <Tab title="Personal details">
            <PersonalDetails data={data} refetch={refetch} />
          </Tab>
          <Tab title="Password settings">
            <PasswordSettings />
          </Tab>
          <Tab title="Subscription">
            <Subscription />
          </Tab>
          <Tab title="Certification">
            <Certification />
          </Tab>
        </Tabs>
      </div>
    </AppLayout>
  );
}
