import { request } from "../hooks/api";
import { OnboardingType, SignInType, SignUpType } from "../hooks/types";
import { preparedParams } from "./_helpers";

class AuthService {
  async signup(payload: SignUpType) {
    try {
      const response = await request(
        `/authentication/register`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async verifyEmail(
    url: string,
    payload: { email?: string; token?: string },
    id: string | undefined
  ) {
    const requestParams = preparedParams(url, id);
    try {
      const response = await request(
        requestParams,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async login(payload: SignInType) {
    try {
      const response = await request(
        `/authentication/login`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async onBoarding(payload: OnboardingType) {
    try {
      const response = await request(
        `/authentication/onboard`,
        "POST",
        payload,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const response = await request(
        `/authentication/logout`,
        "POST",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getProfile() {
    try {
      const response = await request(`/user`, "GET", null, true, false, false);
      return response;
    } catch (error) {
      throw error;
    }
  }
  
}

export default AuthService;

export const login = async (data: SignInType) => {
  try {
    const config = {
      method: "post",
      url: "/authentication/login",
      data,
    };
    const responseData = await request(config, false);
    const accessToken = responseData?.token;
    // cookies.set("scriipoAccess", accessToken, { path: "/" });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};

export const logout = async () => {
  try {
    const config = {
      method: "get",
      url: "/authentication/logout",
    };
    const responseData = await request(config);
    return responseData;
  } catch (error) {
    console.log(error);
  }
};

export const getProfile = async () => {
  try {
    const config = {
      method: "get",
      url: "/user",
    };
    const responseData = await request(config);
    const userProfile = responseData?.data;
    // cookies.set("scriipoUser", userProfile, { path: "/" });
    return responseData;
  } catch (error) {
    console.log(error);
  }
};
