import React from 'react'
import { LandingPageLayout } from '../../../components/layouts'
import Main from './Main'
import Aside from './Aside'

type Props = {}

export default function index({}: Props) {
    const courseClick = () => {}

    return (
        <LandingPageLayout>
            <div className='w-full flex -mt-[6rem] py-[50px] '>
                <Aside 
                handleCourseClick={courseClick}
                />
                <Main />
            </div>
        </LandingPageLayout>
    )
}