import React from 'react'
import { AuthLayout } from '../../../../components/layouts'
import LayoutContainer from './LayoutContainer'
import { CustomButton } from '../../../../components/ui'
import { signup_style } from '../student/style'

type Props = {}

export default function SignupSuccess({}: Props) {
    return (
        <AuthLayout>
            <LayoutContainer className='pb-[50px] mt-[10rem]'>
                <h1 className={signup_style.h1Style}>You are in!</h1>
                <p className={`${signup_style.pStyle} lg:w-[60%] w-[80%] mx-auto py-1`}>To start your music journey, we will ask you some few questions for personalized experience.</p>
                <div className='w-[70%] mx-auto mt-5'>
                    <CustomButton 
                        isLink={true}
                        value="Continue"
                        className='text-white flex justify-center'
                        />
                </div>
            </LayoutContainer>
        </AuthLayout>
    )
}