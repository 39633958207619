import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from "./utils/routes"
import { Provider } from 'react-redux';
import AppMiddleware from './utils/AppMiddleware';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/configureStore';


const router = createBrowserRouter(routes);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <AppMiddleware> */}
          <RouterProvider router={router} />
        {/* </AppMiddleware> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
