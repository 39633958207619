import React from 'react'
import { LandingPageLayout } from '../../../components/layouts';
import { h1Style, h3Style } from '../../waitList/style';
import { TERMS_OF_USE } from '../../../utils/constants';
import terms_img from "../../../assets/images/terms-img.png";

type Props = {}

export default function TermsOfUse({}: Props) {
    return (
        <LandingPageLayout hideSection={true}>
             <div className='-mt-[10.7rem] lg:h-[95vh] pt-[150px] h-[95vh] bg'>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-9 items-center">
                    <div className='w-[75%] mx-auto'>
                        <h1 className={`${h1Style} py-5 text-center lg:text-left`}>Terms of Use</h1>
                        <p className='font-[supreme] text-[17px] text-[#555C74] text-center lg:text-left'>{TERMS_OF_USE.MAIN}</p>
                        <p className='font-[supreme] text-[17px] text-[#010516] mt-10 text-center lg:text-left'>Published on {TERMS_OF_USE.PIBLISH_DATE}</p>
                    </div>
                    <div className='flex justify-end'>
                        <img src={terms_img} alt="privacy_img" />
                    </div>
                </div>
            </div>
            <section className='lg:py-[100px] lg:px-[60px] py-[20px] px-[20px] privacy-policy'>
                <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.PAR_ONE}</p>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Acceptance of terms of use</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.ACCEPTANCE_OF_TERMS}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>User account</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.USER_ACCOUNT}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Use of site</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.USE_OF_SITE}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Content</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.CONTENT}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Intellectual property</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.INTELLECTUAL_PROPERTY}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Payment and subscription</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.PAYMENT_AND_SUB}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Termination</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.TERMINATION}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Limitation of liability</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.LIMITATION_OF_LIABILITY}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Governing law</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.GOVERNING_LAW}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Dispute between users</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.DISPUTE_BTW_USERS}</p>
                </div>
                <div className="mt-10">
                    <h3 className={`${h3Style} mb-3`}>Changes to our Terms of Use</h3>
                    <p className='font-[supreme] font-[400] text-[18px] leading-[28px]'>{TERMS_OF_USE.CHANGES_TO_TERMS}</p>
                </div>
            </section>
        </LandingPageLayout>
    )
}