import { request } from "../hooks/api";

class ReviewService {
  async getReview(id: string) {
    try {
      const response = await request(
        `/review/${id}`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async postReview(
    id: string,
    payload: { rating: number; body: string; positive: boolean }
  ) {
    try {
      const response = await request(
        `/review/${id}`,
        "POST",
        payload,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default ReviewService;
