import React from 'react'
import { GoArrowRight } from 'react-icons/go'

function TrackLearning() {
    return (
        <div className='border-tsa-primary border rounded-lg h-full flex items-center justify-center flex-col px-10 py-2'>
            <div className=''>
                <h4 className='text-[14px] font-bold supreme'>Track your learning activity</h4>
                <p className='text-[12px] supreme'>we'll track your activity and display your most productive days here.</p>
            </div>
            <div className='w-full flex items-center justify-start'>
                <div
                    className="bg-cp-secondary py-2 text-tsa-primary supreme text-[14px] hover:underline cursor-pointer flex justify-center items-center gap-2"
                >
                    Set up a learning habit
                    <GoArrowRight size={20} />
                </div>
            </div>

        </div>
    )
}

export default TrackLearning