import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { VideoPlayer } from '../../../../components/ui';
import { btnStyle, h2Style, h3Style, pStyle } from '../../../waitList/style';
import backIcon from "../../../../assets/icons/backArrow.svg";
import saveIcon from "../../../../assets/icons/saveIcon.svg";
import shareIcon from "../../../../assets/icons/shareIcon.svg";
import levelIcon from "../../../../assets/icons/levelIcon.svg";
import reviewIcon from "../../../../assets/icons/reviewIcon.svg";
import audioIcon from "../../../../assets/icons/speaker.svg";
import durationIcon from "../../../../assets/icons/durationIcon.svg";
import { Tab, Tabs } from '../../../../components/ui/Tab';
import CourseDetails from './CourseDetails';
import CourseContent from './CourseContent';
import CourseReviews from './CourseReviews';
import CourseInstructor from './CourseInstructor';
import LessonOverview from './LessonOverview';
import SubmitAssignmnt from './SubmitAssignmnt';
import { useGetSingleCourse } from '../../../../hooks/react-query/useCourse';
import Loader from '../../../../components/ui/Loader';

function SingleCourse() {
    const { cid } = useParams()
    const { data: courseData, isLoading } = useGetSingleCourse(cid as string)

    if (isLoading) {
        return <Loader />
    }
    return (
        <AppLayout>
            <div className='my-[70px] mx-[20px]'>
                <section className=''>
                    <div className='flex items-start gap-5'>
                        <div className='w-[70%]'>
                            <VideoPlayer style="rounded-[12px] lg:h-[400px] h-[200px]" src={courseData?.data?.introductory_video} />
                            <div className='flex items-center gap-x-7 py-4'>
                                <span className='flex items-center gap-x-1.5 supreme'>
                                    <img src={saveIcon} alt="share" /> Save
                                </span>
                                <span className='flex items-center gap-x-1.5 supreme'>
                                    <img src={shareIcon} alt="save" /> Share
                                </span>
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <CourseContent data={courseData} />
                        </div>
                    </div>
                </section>

                <div className='mt-10 w-[70%]'>
                    <Tabs>
                        <Tab title="OVERVIEW">
                            <LessonOverview />
                        </Tab>
                        <Tab title="ASSIGNMENT">
                            <SubmitAssignmnt />
                        </Tab>
                        <Tab title="REVIEWS">
                            <CourseReviews />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </AppLayout>
    )
}

export default SingleCourse