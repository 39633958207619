import React from 'react';
import user from "../../assets/images/Image-1.png";
import { Link } from 'react-router-dom';

interface InstructorProps {
    instructor: any,   //CourseProps,
    showModal?: () => void
}

const InstructorCard: React.FC<InstructorProps> = ({ instructor }) => {

    return (
        <div className='relative lg:w-[90%] overflow-hidden sm:w-[220px] h-[350px] rounded-[12px]'>
            <Link to={`instructor/${1}`}>
                <div className='rounded-[12px]'>
                    <img src={user} alt={instructor?.author || instructor?.name} className='object-cover w-full h-full' />
                </div>
                <div className='absolute top-0 h-full w-full rounded-[12px] z-20 instructor-img-bg'>
                </div>
                <div className="absolute bottom-[4rem] left-[40%] right-[40%] z-30">
                    <center>
                        <h1 className='font-[700] text-[22px] grid text-white font-[technorSemibold] uppercase'>Kelvin<br />Peter</h1>
                    </center>
                </div>
            </Link>
        </div>
    );
}

export default InstructorCard;