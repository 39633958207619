import { request } from "../hooks/api";
import { CommentProps, LessonProgressProps } from "./_model";

class CourseService {
    async watchLesson(id: string | undefined) {
        try {
            const response = await request(
                `/user/lesson/${id}`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async trackProgress(id: string, payload: LessonProgressProps) {
        try {
            const response = await request(
                `/user/lesson/${id}/tracking`,
                'POST',
                payload,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async commentOnLesson(id: string, payload: CommentProps) {
        try {
            const response = await request(
                `/user/lesson/${id}/comment`,
                'POST',
                payload,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getAllCourses() {
        try {
            const response = await request(
                `/user/courses`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getSingleCourse(id: string) {
        try {
            const response = await request(
                `/user/course/${id}`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getRecommendedCourses() {
        try {
            const response = await request(
                `/user/dashboard/recommended`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getPopularCourses() {
        try {
            const response = await request(
                `/user/dashboard/popular`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getCoursesHistory() {
        try {
            const response = await request(
                `/user/dashboard/popular`,
                'GET',
                null,
                true,
                false,
                false
            );
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default CourseService;
