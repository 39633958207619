import React from 'react';
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
    style: string,
    src: string | undefined,
    type?: string
}

export default function VideoPlayer({ src, type, style }: VideoPlayerProps) {
    return (
        <div className='h-[50vh]'>
            <ReactPlayer
                url={src}
                className="react-player"
                controls={true}
                style={{ pointerEvents: 'auto' }}
            />
        </div>

    )
}
