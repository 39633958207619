import React, { ReactNode } from 'react';
import logo from '../../../assets/images/logo-mini.svg';
import { Link } from 'react-router-dom';

type Props = {
    children: ReactNode
}

export default function AuthLayout({ children }: Props) {
  return (
    <section className='bg h-[100vh] pt-7  w-full overflow-y-auto'>
        <Link to="/">
          <img src={logo} alt="logo" className='pl-10' />
        </Link>

        <div>{ children }</div>
    </section>
  )
}