const h1Style = "font-[900] lg:text-[55px] text-[30px] lg:leading-[65px] leading-[45px] font-[technorBold] text-tsa-textBlack";
const h2Style = "font-[700] lg:text-[40px] text-[25px] lg:leading-[52px] leading-[40px] font-[technorBold] text-tsa-textBlack";
const h3Style = "font-[700] text-[25px] leading-[32px] font-[technorBold] text-tsa-textBlack";
const h4Style = "font-[700] text-[18px] leading-[25px] font-[technorBold] text-tsa-textBlack";
const pStyle = "font-[400] lg:text-[18px] text-[16px] leading-[27px] font-[supreme] text-tsa-textGrey";
const btnStyle = "bg-tsa-primary lg:p-[18px] p-[13px] rounded-[8px] font-[supreme] text-white lg:text-[14px] text-[13px] tracking-wide";


export  {
    h1Style,
    h2Style,
    h3Style,
    h4Style,
    pStyle,
    btnStyle
}