import { request } from "../hooks/api"; // Make sure to import your request function

class InstructorService {
  async getInstructorPage(id: string) {
    try {
      const response = await request(
        `/instructor/public/${id}`,
        "GET",
        null,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default InstructorService;
