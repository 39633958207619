import React from 'react'
import { LandingPageLayout } from '../../../components/layouts'
import { h1Style, h3Style, pStyle } from '../../waitList/style'
import { SearchBox } from '../../../components/ui'
import { ABOUT } from '../../../utils/constants'

type Props = {}

export default function Search({}: Props) {
    const handleSearch = () => {}

    return (
        <LandingPageLayout>
            <div className='-mt-[10.7rem] lg:h-[70vh] pt-[230px] h-[60vh] bg'>
                <h1 className={`${h1Style} text-center`}>How can we help?</h1>
                <div className='lg:w-[42%] w-[70%] mx-auto mt-10'>
                    <SearchBox handleSearch={handleSearch} placeholder='Search' />
                </div>
            </div>
            <section className='lg:py-[80px] py-[40px] md:px-[100px] px-[20px]'>
                <h3 className={`${h3Style} text-center mb-3`}>How do i cancel my subscription?</h3>
                <p className={`${pStyle} text-center mb-7`}>Last updated: 2 days ago</p>
                <p className={`${pStyle} lg:w-[60%] md:w-[80%] w-[95%] mx-auto mb-3`}>{ABOUT}</p>   

                <div className='lg:w-[60%] md:w-[80%] w-[95%] mx-auto bg-[#F1F2F3] mt-10 py-[24px]'>
                    <p className='text-center font-[supreme] font-[16px] mb-4 text-[#010516]'>Hope you find this helpful?</p>
                    <div className='flex font-[supreme] items-center justify-center gap-x-5'>
                        <button className='border border-[#010516] rounded-[8px] text-[14px] py-[8px] px-[12px]'>Yes</button>
                        <button className='border border-[#010516] rounded-[8px] text-[14px] py-[8px] px-[12px]'>No</button>
                    </div>
                </div>           
            </section>
        </LandingPageLayout>
    )
}