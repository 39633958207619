import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import CourseCard from '../home/CourseCard'
import { FormControl, InputLabel, MenuItem, Pagination, PaginationItem, Select, Stack, SelectChangeEvent } from '@mui/material'
import { useGetAllCourses } from '../../../../hooks/react-query/useCourse'
import Loader from '../../../../components/ui/Loader'

type Props = {}

export default function MyCourses({ }: Props) {
  const [age, setAge] = React.useState('');

  const { isLoading, data } = useGetAllCourses()

  if (isLoading) {
    return <Loader />
  }

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
    <AppLayout>
      <div className='my-[70px] mx-[20px]'>
        <div className='my-10 flex items-center justify-between'>
          <div className='flex items-center justify-start gap-2 w-[20%]'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Music</MenuItem>
                <MenuItem value={20}>Dance</MenuItem>
                <MenuItem value={30}>Art</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Skill Level</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Beginner</MenuItem>
                <MenuItem value={20}>Intermediate</MenuItem>
                <MenuItem value={30}>Advanced</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
          <Pagination count={10} />

        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
          {data?.data?.map((item: any) => (
            <CourseCard course={item} />
          ))}
        </div>

      </div>
    </AppLayout>
  )
}