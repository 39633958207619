import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import Certificate from './Certificate';
import { FaCheckCircle } from 'react-icons/fa';
import { LuShare2 } from "react-icons/lu";
import { FiDownload } from "react-icons/fi";


function Certification() {

    return (
        <div className='w-full pb-[200px]'>
            <h1 className={`font-bold supreme text-[26px] my-2`}>Certification</h1>
            <div className='grid grid-cols-2 gap-5'>
                <div className='p-5 bg-gray-50'>
                    <Certificate certificateId={123} />
                    <div className='mt-2 mb-3'>
                        <h3 className='text-[14px] font-[500]  font-[supreme]  text-[#010516]'>{'Music Industry Networking and Relationship Building'}</h3>
                        <p className='text-[12px] font-[400]  font-[supreme] text-[#919397]'>By {"Kelvin Peter"}</p>
                    </div>
                    <div className='flex items-center justify-between'>
                        <span className='flex items-center gap-2'>
                            <FaCheckCircle className="text-green-500" />
                            <p className='supreme'>Completed</p>
                        </span>
                        <span className='flex items-center gap-2'>
                            <LuShare2 />
                            <FiDownload />
                        </span>
                    </div>
                </div>
                <div className='p-5 bg-gray-50'>
                    <Certificate certificateId={123} />
                    <div className='mt-2 mb-3'>
                        <h3 className='text-[14px] font-[500]  font-[supreme]  text-[#010516]'>{'Music Industry Networking and Relationship Building'}</h3>
                        <p className='text-[12px] font-[400]  font-[supreme] text-[#919397]'>By {"Kelvin Peter"}</p>
                    </div>
                    <div className='flex items-center justify-between'>
                        <span className='flex items-center gap-2'>
                            <FaCheckCircle className="text-green-500" />
                            <p className='supreme'>Completed</p>
                        </span>
                        <span className='flex items-center gap-2'>
                            <LuShare2 />
                            <FiDownload />
                        </span>
                    </div>
                </div>
                <div className='p-5 bg-gray-50'>
                    <Certificate certificateId={123} />
                    <div className='mt-2 mb-3'>
                        <h3 className='text-[14px] font-[500]  font-[supreme]  text-[#010516]'>{'Music Industry Networking and Relationship Building'}</h3>
                        <p className='text-[12px] font-[400]  font-[supreme] text-[#919397]'>By {"Kelvin Peter"}</p>
                    </div>
                    <div className='flex items-center justify-between'>
                        <span className='flex items-center gap-2'>
                            <FaCheckCircle className="text-green-500" />
                            <p className='supreme'>Completed</p>
                        </span>
                        <span className='flex items-center gap-2'>
                            <LuShare2 />
                            <FiDownload />
                        </span>
                    </div>
                </div>
                <div className='p-5 bg-gray-50'>
                    <Certificate certificateId={123} />
                    <div className='mt-2 mb-3'>
                        <h3 className='text-[14px] font-[500]  font-[supreme]  text-[#010516]'>{'Music Industry Networking and Relationship Building'}</h3>
                        <p className='text-[12px] font-[400]  font-[supreme] text-[#919397]'>By {"Kelvin Peter"}</p>
                    </div>
                    <div className='flex items-center justify-between'>
                        <span className='flex items-center gap-2'>
                            <FaCheckCircle className="text-green-500" />
                            <p className='supreme'>Completed</p>
                        </span>
                        <span className='flex items-center gap-2'>
                            <LuShare2 />
                            <FiDownload />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Certification