import React from 'react';
import searchIcon from '../../assets/icons/search.svg';

type Props = {
    handleSearch: () => void,
    placeholder?: string
}

export default function SearchBox({ handleSearch, placeholder }: Props) {
  return (
    <div className='lg:w-full bg-white flex items-center bg-transparent rounded-[8px] font-[supreme] border border-[#DCDEE2] py-3 px-5 gap-x-3'>
        <img src={searchIcon} alt="search" />
        <input 
            type="search" 
            onChange={handleSearch}
            className='w-full outline-0 bg-transparent border-0 text-tsa-textGrey text-[13px]'
            placeholder={placeholder}
        />
    </div>
  )
}