import { QueryClient, useMutation } from "react-query";
import AuthService from "../../services/auth.service";
import { OnboardingType, SignInType, SignUpType } from "../types";

const authService = new AuthService();

const queryClient = new QueryClient();

export const useRegisterUser = () => {
  return useMutation((payload: SignUpType) => authService.signup(payload));
};

export const useLogin = () => {
  return useMutation((payload: SignInType) => authService.login(payload));
};

export const useUpdateProfile = () => {
  return useMutation((data: any) => authService.updateProfile(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};
export const useUpdatePassword = () => {
  return useMutation((data: any) => authService.updatePassword(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};

export const useInitiateEmailVerification = () => {
  return useMutation(
    ({
      url,
      payload,
      id,
    }: {
      url: string;
      payload: { email: string };
      id?: string | undefined;
    }) => authService.verifyEmail(url, payload, id)
  );
};

export const useVerifyEmail = () => {
  return useMutation(
    ({
      url,
      payload,
      id,
    }: {
      url: string;
      payload: { token: string };
      id?: string | undefined;
    }) => authService.verifyEmail(url, payload, id)
  );
};

export const useOnboarding = () => {
  return useMutation((payload: OnboardingType) =>
    authService.onBoarding(payload)
  );
};
