'use client';
import React, { forwardRef, useEffect, useState } from 'react';
import logoImg from '../../../../assets/images/logo-mini.svg';
import { pStyle } from '../../../waitList/style';
import QRCode from 'qrcode';

interface certProps {
    certificateId: number
}

const Certificate = forwardRef<HTMLDivElement, certProps>(({ certificateId }) => {
    const [src, setSrc] = useState('');
    const generateQRcode = async () => {
        try {
            const result = await QRCode.toDataURL(`${process.env.REACT_APP_CLIENT}/app/courses/certificate/${certificateId}`);
            setSrc(result);
        } catch (error) {
            console.log(error, 'error from qrcode generator');
        }
    };

    useEffect(() => {
        generateQRcode();
    }, [certificateId]);

    return (
        <div
            className="relative grid gap-2 p-4"
            style={{ width: '100%', height: '300px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
        >
            <img src="/images/certbg.svg" alt="scriipo certificate" className="absolute w-full h-8 object-cover" />
            <div className="relative z-10 grid gap-2 p-4 mt-3">
                <div className="flex items-start justify-between">
                    <div>
                        <h3 className="font-bold supreme text-[10px] my-1">CERTIFICATE OF COMPLETION</h3>
                        <h1 className="supreme font-[500] text-[18px] mt-1">Mastering Afrobeat Music Production</h1>
                    </div>
                    <img src={logoImg} alt="logo" className="w-[50px]" />
                </div>

                <div className="grid gap-1">
                    <div className="w-[100%]">
                        <h3 className="font-[800] alex-brush-regular text-[18px] italic my-1">Victor Asuquo</h3>
                        <hr className="border-gray-500" />
                        <p className={` supreme text-[10px] mt-1`}>
                            Congratulations on your achievement and dedication to mastering the art of Afrobeat music production. We look forward to seeing your continued growth and success in the music industry.
                        </p>
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="grid gap-1 w-[60%] grid-cols-2">
                        <div className="flex items-center justify-center flex-col">
                            <h3 className="supreme text-[12px] my-1">12/03/2024</h3>
                            <hr className="border-gray-500 w-[50%]" />
                            <p className={`${pStyle} supreme text-[10px] mt-1`}>Date</p>
                        </div>
                        <div className="flex items-center justify-center flex-col">
                            <h3 className="font-[800] alex-brush-regular text-[18px] italic my-1">Gospel Obi</h3>
                            <hr className="border-gray-500 w-[50%]" />
                            <p className={`${pStyle} supreme text-[10px] mt-1`}>Signature</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <img src={src} className="w-[50px] h-[50px]" alt="QR code" />
                        <p className="indie-flower-regular text-[10px] text-tsa-primary">Scan to verify</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

// Set the displayName for the component
Certificate.displayName = 'Certificate';

export default Certificate;
