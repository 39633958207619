import { Rating } from '@mui/material';
import { formatDate1 } from '../../../../utils/formattedDate';

function ReviewCard({ data }: any) {
    return (
        <div className='grid gap-3 border-b pb-2'>
            <div className='flex items-center gap-x-4 mt-4'>
                <img
                    src={data?._user?.image}
                    alt="author"
                    className='lg:h-[30px] lg:w-[30px] h-[30px] w-[30px] rounded-full object-cover'
                />
                <div className='grid gap-2'>
                    <p className='supreme font-[500] text-[16px] text-[#010516] capitalize'>
                        {data?._user?.fullname}
                    </p>
                    <div className='flex items-center justify-end gap-2'>
                        <Rating
                            name="half-rating-read"
                            defaultValue={data.rating}
                            precision={0.5}
                            readOnly
                            size="small"
                        />
                        <span className='text-[12px] text-tsa-primary supreme'>{formatDate1(data?.updatedAt)}</span>
                    </div>
                </div>
            </div>
            <p className='supreme text-[12px] leading-[20px] text-[#010516]'>{data?.body}</p>
        </div>
    );
}

export default ReviewCard;
