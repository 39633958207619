const SIGN_UP = {
    HEADING_TEXT: "Create account",
    PARAGRAPH_TEXT: "Sign up to start your musical journey",
    EMAIL_LABEL: "Email address",
    EMAIL_PLACEHOLDER: "john.doe@example.com",
    PASSWORD_LABEL: "Password",
    PASSWORD_PLACEHOLDER: "***********",
    COUNTRY_LABEL: "Country",
    COUNTRY_PLACEHOLDER: "Select country",
    BUTTON_TEXT: "Create an account"
}

const SIGN_IN = {
    HEADING_TEXT: "Welcome back!",
    PARAGRAPH_TEXT: "Let’s continue your musical journey",
    EMAIL_LABEL: "Email address",
    EMAIL_PLACEHOLDER: "john.doe@example.com",
    PASSWORD_LABEL: "Password",
    PASSWORD_PLACEHOLDER: "***********",
    BUTTON_TEXT: "Login"
}

const RESET_PASSWORD = {
    HEADING_TEXT: "Reset password",
    PARAGRAPH_TEXT: "Enter the code that was sent to tour email and create new password",
    RESET_CODE_LABEL: "Reset code",
    RESET_CODE_PLACEHOLDER: "code",
    PASSWORD_LABEL: "Password",
    PASSWORD_PLACEHOLDER: "***********",
    CONFIRM_PASSWORD_LABEL: "Confirm new Password",
    CONFIRM_PASSWORD_PLACEHOLDER: "***********",
    BUTTON_TEXT: "Continue"
}



export {
    SIGN_UP,
    SIGN_IN,
    RESET_PASSWORD
}