import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import InProgess from './inProgress'
import { Tab, Tabs } from '../../../../components/ui/Tab'
import Completed from './completed'
import Saved from './saved'
import { useGetRecommendedCourses } from '../../../../hooks/react-query/useCourse'

type Props = {}

export default function MyLearning({ }: Props) {
    const { data: Courses, isLoading: isLoadingRecommended } = useGetRecommendedCourses();
    return (
        <AppLayout>
            <div className='my-[70px] mx-[20px] w-[60%]'>
                <Tabs>
                    <Tab title="In Progress">
                        <InProgess Courses={Courses?.data} />
                    </Tab>
                    <Tab title="Completed">
                        <Completed Courses={Courses?.data} />
                    </Tab>
                    <Tab title="Saved">
                        <Saved Courses={Courses?.data} />
                    </Tab>
                </Tabs>
            </div>
        </AppLayout>
    )
}