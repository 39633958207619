import { Input, InputProps } from '@mui/material'
import { Field } from 'formik'
import React from 'react'

interface Props extends InputProps {
    useFormikForm?: boolean,
    component?: any
}

const inputStyle = 'supreme border border-[#DCDEE2] rounded-[6px] py-[12px] px-[16px] w-full ';

export default function CustomTextbox({ onChange, type, placeholder, className, required, id, name, useFormikForm, component } : Props) {
   return (
    <>
        {useFormikForm ? (
            <Field 
                name={name} 
                id={id} 
                type={type} 
                placeholder={placeholder}
                className={`${className} ${inputStyle} focus:outline-[#0E3939]/[.5] active:outline-[#0E3939]`}
                component={component}
                />
        ) : (
            <input 
                onChange={onChange} 
                required={required}
                type={type} 
                placeholder={placeholder}
                className={`${className} ${inputStyle} focus:outline-[#0E3939]/[.5] active:outline-[#0E3939]`}
                id={id}
                />
        )} 
    </>
   )
}