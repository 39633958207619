import React, { useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdatePassword } from "../../../../hooks/react-query/useAuthUser"; // Assume this is a custom hook for password update
import Loader from "../../../../components/ui/Loader";
import { toast } from "react-toastify";

function PasswordSettings() {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const { mutate, data, isLoading } = useUpdatePassword();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current password is required"),
      newPassword: Yup.string().required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required")
    }),
    onSubmit: values => {
      const payload = {
        old_password: values.currentPassword,
        new_password: values.newPassword
      };
      mutate(payload, {
        onSuccess: () => {
          toast('password update successful')
          // Handle success, e.g., show a success message, reset form, etc.
        }
      });
    }
  });

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="w-full flex flex-col gap-3 pt-4 supreme">
      <h1 className="supreme text-[16px] font-semibold text-gray-700">
        Password Settings
      </h1>
      <div className="bg-[#F1F2F3] h-[40vh] md:w-[70%] p-4 flex flex-col gap-5 pt-6 rounded-md">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">
              Current password
            </p>
            <div className="relative w-[90%]">
              <input
                type={showPassword1 ? "text" : "password"}
                name="currentPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="Current password"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility1}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {formik.touched.currentPassword && formik.errors.currentPassword ? (
                <div className="text-red-500 text-sm">{formik.errors.currentPassword}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">New password</p>
            <div className="relative w-[90%]">
              <input
                type={showPassword2 ? "text" : "password"}
                name="newPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="New password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility2}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="text-red-500 text-sm">{formik.errors.newPassword}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">Re-enter new password</p>
            <div className="relative w-[90%]">
              <input
                type={showPassword3 ? "text" : "password"}
                name="confirmPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="Re-enter new password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility3}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="bg-[#1C7272] text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme mt-2"
          >
            Save changes
          </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordSettings;
