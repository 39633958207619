import React, { memo, useMemo, useState } from 'react'
import { signup_style } from '../style'
import { CustomButton } from '../../../../../components/ui'
import { USER_INTERESTS } from '../../../../../utils/constants'
import { Checkbox } from '@mui/material'
import { toast } from 'react-toastify'

type Props = {
    setValues: (value: string[]) => void;
    values: string[] | never
}

export default function UserInterestPage({ setValues, values }: Props) {
    const [ interests, setInterests ] = useState<string[] | never>(values);

    const onChange = (arg: string) => {
        // add non-existing interests, else remove existing interests 
        setInterests((prevInterests: string[]) => {
            if(prevInterests.includes(arg)){
                return prevInterests.filter((item) => item !== arg)
            }else {
                return [...prevInterests, arg]
            }
        })
    }

    const onClick = () => {
        if(interests.length){
            setValues(interests)
        }else {
            toast.error('Select atleast one area of interest')
        }
    }

    return (
        <>
            <h1 className={signup_style.h1Style}>What area are you interested in?</h1>
            <div className='w-[83%] mx-auto grid mt-7 gap-y-2'>
                <ul className='lg:h-[40vh] h-[50vh] overflow-y-auto grid lg:gap-y-4 gap-y-3'>
                {USER_INTERESTS.map((interest, i) => (
                    <li key={i} className='flex items-center py-2.5 px-2 border border-[#DCDEE2] rounded-[6px]'>
                        <Checkbox 
                            id={interest.value} 
                            onChange={() => onChange(interest.value)} 
                            style={{ color: '#1C7272' }}
                            checked={interests.includes(interest.value) || false}
                            />
                        <label 
                            htmlFor={interest.value} 
                            className='supreme hover:cursor-pointer lg:text-[18px] text-[16px] text-[#555C74] font-[500]'>
                                {interest.text}
                        </label>
                    </li>
                ))}
                </ul>
                <CustomButton 
                    isLink={false}
                    onClick={onClick}
                    value="Continue"
                    className='text-white mt-5'
                    />
            </div>
        </>
    )
}