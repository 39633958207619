import { useState } from 'react';
import { IoClose, IoMenu, IoNotifications, } from 'react-icons/io5';
import { RxCaretDown } from 'react-icons/rx';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../../hooks/react-query/useAuth';
import Loader from '../../ui/Loader';

interface DashboardNavbarProps {
    setSidebarOpen: any;
    isSidebarOpen: boolean;
    data: any
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({
    setSidebarOpen,
    isSidebarOpen,
    data: userData
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };


    const openSidebar = () => {
        setSidebarOpen(true);
    };
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/auth/signin');
    }
    return (
        <div className="bg-cp-primary py-3 flex md:left-[220px] left-0 fixed top-0 right-0  bg-white supreme items-center h-[50px] justify-between md:px-5 px-4 shadow-md z-[10000]">
            <h1 className='font-bold md:text-[20px] md:block supreme capitalize'>
                Welcome {userData?.data?.firstname}!
            </h1>
            <div className="items-center justify-end  border border-gray-400 py-1 px-3 hidden md:flex ">
                <FaSearch className="text-gray-400 mr-2" />
                <input type="text" className="outline-none border-none bg-transparent flex-grow px-2 rounded w-full" placeholder="Search instructor, courses" />
            </div>
            <div className=" flex items-center justify-end gap-6 supreme">
                <div className="relative">
                    <IoNotifications color="#555C74" size={20} />
                    <div className="bg-yellow-500 h-[8px] w-[8px] rounded-full flex items-center justify-center absolute top-0 right-[0]">
                    </div>
                </div>
                <div className="md:flex items-center gap-2 hidden">

                    <img className="h-10 w-10 rounded-full bg-green-800 text-white flex items-center justify-center" src={userData?.data?.image || `https://api.dicebear.com/8.x/initials/svg?seed=${userData?.data?.firstname}-${userData?.data?.lastname}`} alt="" />
                    <div className='grid'>
                        <p className="capitalize font-bold text-[14px] supreme">{userData?.data?.firstname} {userData?.data?.lastname}</p>
                        <p className="text-[12px] capitalize supreme">{userData?.data?.email}</p>
                    </div>
                    <div className="relative">
                        <RxCaretDown
                            size={25}
                            className="cursor-pointer"
                            onClick={toggleDropdown}
                        />
                        {isDropdownOpen && (
                            <div className="absolute z-[10001] p-3 bg-cp-primary top-full right-0 mt-1 w-50 text-grey bg-white rounded shadow-lg">
                                <div className="p-2">
                                    <p className="capitalize font-bold text-[14px] supreme">
                                        {userData?.data?.firstname} {userData?.data?.lastname}
                                    </p>
                                    <p className="text-[12px]  supreme capitalize">{userData?.data?.email}</p>
                                </div>
                                <button className="block bg-green-800 text-white w-full py-2 text-left px-4 bg-sa-golden rounded-lg hover:bg-cp-primary" onClick={handleLogout}>
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:hidden">
                    {!isSidebarOpen ? (
                        <IoMenu
                            size={24}
                            className="h-6 w-6 cursor-pointer"
                            onClick={openSidebar}
                        />
                    ) : (
                        <IoClose
                            size={24}
                            className="h-6 w-6 cursor-pointer"
                            onClick={closeSidebar}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardNavbar;
