const requestHeader = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
};

const getLocation = () => {
  return window.location;
};

  const logout = () => {
    // localStorage.clear();
    // window.location.href = "/auth/signin";
  };

/**
 *
 * @param {string} url
 * @param {string, [GET, POST, PATCH, PUT...]} method
 * @param {payload} payload
 * @param {boolean} token
 * @param {boolean} text
 * @param {boolean} form
 * @param {string | null} xHash
 * @returns Response Data;
 */

const BASE_URL = "https://pinnacle-gejv.onrender.com/v1";

const API_USER_URL = BASE_URL;

async function request(url, method, payload, token = true, text, form) {
  // get current browser location path
  const location = getLocation();
  const isAuthPage = location.pathname.includes("/auth/");

  const bearerToken = localStorage.getItem("scriipo-token");

  if (bearerToken) {
    requestHeader["authorization"] = `Bearer ${bearerToken}`;
  }

  requestHeader["Content-Type"] =
    form === true ? "multipart/form-data" : "application/json";

  if (method === "GET") {
    return fetch(API_USER_URL + url, {
      method,
      headers: Object.assign(requestHeader),
    })
      .then((res) => {
        if (!isAuthPage) {
          // unauthorized access shouldn't apply to  auth pages
          if (res.status === 403 || res.status === 401) {
            // Redirect to the login page
            logout();
            throw new Error("Access forbidden. Redirecting to login page.");
          } else if (text === true) {
            return res.text();
          } else {
            return res.json();
          }
        } else {
          if (text === true) {
            return res.text();
          } else {
            return res.json();
          }
        }
      })
      .catch((err) => {
        console.error(`Request Error ${url}: `, err);
        throw new Error(err);
        // return err;
      });
  } else {
    return fetch(API_USER_URL + url, {
      method,
      headers: Object.assign(requestHeader),
      body: form === true ? payload : JSON.stringify(payload),
    })
      .then((res) => {
        if (!isAuthPage) {
          if (res.status === 403 || res.status === 401) {
            logout();
            throw new Error("Access forbidden. Redirecting to login page.");
            // Redirect to the login page
            // window.location.href = "/auth/login";
          } else if (text === true) {
            return res.text();
          } else {
            return res.json();
          }
        } else {
          if (text === true) {
            return res.text();
          } else {
            return res.json();
          }
        }
      })
      .catch((err) => {
        console.error(`Request Error ${url}: `, err);
        throw new Error(err);
      });
  }
}

export { request };
