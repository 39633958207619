import React from 'react'
import { AppLayout } from '../../../../components/layouts'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { VideoPlayer } from '../../../../components/ui';
import { btnStyle, h3Style, pStyle } from '../../../waitList/style';
import saveIcon from "../../../../assets/icons/saveIcon.svg";
import shareIcon from "../../../../assets/icons/shareIcon.svg";
import levelIcon from "../../../../assets/icons/levelIcon.svg";
import reviewIcon from "../../../../assets/icons/reviewIcon.svg";
import audioIcon from "../../../../assets/icons/speaker.svg";
import durationIcon from "../../../../assets/icons/durationIcon.svg";
import { Tab, Tabs } from '../../../../components/ui/Tab';
import CourseDetails from './CourseDetails';
import CourseContent from './CourseContent';
import CourseReviews from './CourseReviews';
import CourseInstructor from './CourseInstructor';
import { useGetSingleCourse } from '../../../../hooks/react-query/useCourse';
import Loader from '../../../../components/ui/Loader';
import { useGetReview } from '../../../../hooks/react-query/useReview';
import { formatDuration } from '../../../../utils/formattedDate';
import { useGetInstructorPage } from '../../../../hooks/react-query/useInstructor';

function CourseEnrollment() {
    const { id } = useParams()
    const { data: courseData, isLoading } = useGetSingleCourse(id as string)
    const { data: reviewData, isLoading: reviewLoading } = useGetReview(id as string);
    const { data: instructorData, isLoading: instructorLoading, } = useGetInstructorPage(courseData?.data?.main_contributor?._id);
    if (isLoading || reviewLoading || instructorLoading) {
        return <Loader />
    }
    return (
        <AppLayout>
            <div className='my-[70px] mx-[20px]'>
                <section className=''>
                    <div className='flex items-center gap-5'>
                        <div className='w-[60%]'>
                            <VideoPlayer style="rounded-[12px] lg:h-[400px] h-[200px]" src={courseData?.data?.introductory_video} />
                            <div className='flex items-center gap-x-7 py-4'>
                                <span className='flex items-center gap-x-1.5 supreme'>
                                    <img src={saveIcon} alt="share" /> Save
                                </span>
                                <span className='flex items-center gap-x-1.5 supreme'>
                                    <img src={shareIcon} alt="save" /> Share
                                </span>
                            </div>
                        </div>
                        <div className='w-[40%]'>
                            <h2 className={`font-[500] text-[30px] supreme`}>{courseData?.data?.title}</h2>
                            <p className={`${pStyle} supreme py-1`}>By {courseData?.data?.main_contributor.name} {courseData?.data?.main_contributor.nickname}</p>
                            <ul className='grid gap-y-3 py-5'>
                                <li className='flex items-center gap-x-2'>
                                    <span className='flex items-center gap-x-1 supreme'>
                                        <img src={levelIcon} alt="level" />&nbsp;
                                        Level
                                    </span>
                                    <span className='supreme'>{courseData?.data?.level}</span>
                                </li>
                                <li className='flex items-center gap-x-2'>
                                    <span className='flex items-center gap-x-1 supreme'>
                                        <img src={reviewIcon} alt="review" />&nbsp;
                                        Review
                                    </span>
                                    <span className='supreme'>90% positive review</span>
                                </li>
                                <li className='flex items-center gap-x-2'>
                                    <span className='flex items-center gap-x-1 supreme'>
                                        <img src={audioIcon} alt="audio" />&nbsp;
                                        Audio
                                    </span>
                                    <span className='supreme'>{courseData?.data?.language}</span>
                                </li>
                                <li className='flex items-center gap-x-2'>
                                    <span className='flex items-center gap-x-1 supreme'>
                                        <img src={durationIcon} alt="duration" />&nbsp;
                                        Duration
                                    </span>
                                    <span className='supreme'>{courseData?.data?.lessons.length} lessons ({formatDuration(courseData?.data?.duration)})</span>
                                </li>
                            </ul>
                            <Link to={"/app/my-learning"} className={`w-full supreme flex justify-center mt-3 ${btnStyle}`}>Enroll Now</Link>
                        </div>
                    </div>
                </section>

                <div className='mt-10 w-[60%]'>
                    <Tabs>
                        <Tab title="DESCRIPTION">
                            <CourseDetails data={courseData} />
                        </Tab>
                        <Tab title="COURSE CONTENT">
                            <div className='mt-7 pb-[40px]'>
                                <h3 className={`${h3Style} supreme text-[20px] my-2`}>Course content</h3>
                                <CourseContent data={courseData} />
                            </div>

                        </Tab>
                        <Tab title="REVIEWS">
                            <CourseReviews reviewData={reviewData} />
                        </Tab>
                        <Tab title="INSTRUCTOR">
                            <CourseInstructor instructorData={instructorData.data} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </AppLayout>
    )
}

export default CourseEnrollment