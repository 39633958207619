import React from 'react'
import { h3Style, pStyle } from '../../../waitList/style'
import { GoDotFill } from "react-icons/go";
import { FaStar } from "react-icons/fa";
import { FaClockRotateLeft } from "react-icons/fa6";

function LessonOverview() {
    return (
        <section className='pb-[40px] w-full'>
            <div className='grid'>
                <div className='grid gap-3'>
                    <div className='grid gap-1'>
                        <h3 className={`${h3Style} supreme text-[20px] my-2`}>Mastering afrobeat music production</h3>
                        <div className='flex items-center justify-start gap-2'>
                            <h5 className={`font-semibold supreme text-[14px] my-2`}>Gospel Obi</h5>
                            <GoDotFill className='text-[12px] text-gray-400' />
                            <p className={`supreme text-[14px] my-2`}>Music Executive & Producer</p>
                            <GoDotFill className='text-[12px] text-gray-400' />
                        </div>
                    </div>

                    <div className='flex items-center justify-start gap-7'>
                        <div className='flex items-center justify-start flex-col'>
                            <span className={`${h3Style} supreme text-[20px] my-2 flex items-center gap-1`}>4.5 <FaStar size={13} className='text-yellow-500' /></span>
                            <p className={`supreme text-[14px] my-2`}>Course Rating</p>
                        </div>
                        <div className='flex items-center justify-start flex-col'>
                            <span className={`${h3Style} supreme text-[20px] my-2 flex items-center gap-1`}>67,190</span>
                            <p className={`supreme text-[14px] my-2`}>Students</p>
                        </div>
                        <div className='flex items-center justify-start flex-col'>
                            <span className={`${h3Style} supreme text-[20px] my-2 flex items-center gap-1`}>77 hours</span>
                            <p className={`supreme text-[14px] my-2`}>Total</p>
                        </div>
                    </div>

                    <div className='flex items-center justify-start gap-2'>
                        <FaClockRotateLeft className={`supreme text-[14px] my-2 text-stone-500`} />
                        <p className={`supreme text-[14px] my-2`}>Last updated April 2024</p>
                    </div>

                    <p className={`${pStyle} supreme  text-[12px] mt-3`}>Designed specifically for newcomers, this course covers the foundational skills and knowledge you need to begin creating your own music. You’ll learn the basics of audio principles, how to navigate digital audio workstations (DAWs), and essential techniques in recording, mixing, and mastering. This course provides a structured and comprehensive introduction to music production, perfect for those with little to no prior experience.<br /><br /> By the end of this course, you will have a solid understanding of the music production process and the skills to start creating your own music.</p>
                </div>
            </div>
        </section>
    )
}

export default LessonOverview