


const available_classes = [
    "Artist development", "Music business", 
    "Music marketing & Public relation", "Music production", 
    "Song writing", "Sound engineering", "Sound mixing", "Vocals"
]

export const appLinks = [
    { id: 0, name: 'Home', route: '/app/home', img: 'dashboard.svg' },
    { id: 1, name: 'my learning', route: '/app/my-learning', img: 'subscription.svg' },
    { id: 2, name: 'courses', route: '/app/courses', img: 'study.svg' },
    { id: 3, name: 'account', route: '/app/account', img: 'profile.svg' },
];



export const tabItems = [
    { id: 0, name: 'Description' },
    { id: 1, name: 'Review' }
];

export const reviews = [
    { name: 'Leonardo Da Vinci', imgSrc: 'https://i.pinimg.com/474x/22/f9/6a/22f96af983c8d13b751ae874291d34c6.jpg', comment: 'Loved the course. I\'ve learned some very subtle tecniques, expecially on leaves.' },
    { name: 'Titania S', imgSrc: 'https://i.pinimg.com/736x/39/92/db/3992db3db9d4eeac0c0f24ebb1c1d3b2.jpg', comment: 'I loved the course, it had been a long time since I had experimented with watercolors and now I will do it more often thanks to Kitani Studio' },
    { name: 'Zhirkov', imgSrc: 'https://i.pinimg.com/474x/78/e0/b7/78e0b7588e07a85fc4a0f1cd20271870.jpg', comment: 'Yes. I just emphasize that the use of Photoshop, for non-users, becomes difficult to follow. What requires a course to master it. Safe and very didactic teacher.' },
    { name: 'Miphoska', imgSrc: 'https://i.pinimg.com/474x/4c/31/ca/4c31ca4229f3240ec02151da4c21f888.jpg', comment: 'I haven\'t finished the course yet, as I would like to have some feedback from the teacher, about the comments I shared on the forum 3 months ago, and I still haven\'t had any answer. I think the course is well structured, however the explanations and videos are very quick for beginners. However, it is good to go practicing.' },
]

export const packages = [
    { 
        name: 'Forever Free', 
        price: 0, 
        description: 'monthly review invites Plus 300 free SMS Invites', 
        isRecommended: false, 
        features: ['Monthly Price', 'Number of Devices', 'Access to Scheduled Programs', 'Bonus Content', 'Download classes']
    },
    { 
        name: 'Starter plan', 
        price: 11, 
        description: 'monthly review invites Plus 300 free SMS Invites', 
        isRecommended: false, 
        features: ['Monthly Price', 'Number of Devices', 'Access to Scheduled Programs', 'Bonus Content', 'Download classes']
    },
    { 
        name: 'starter plan', 
        price: 75, 
        description: 'monthly review invites Plus 300 free SMS Invites', 
        isRecommended: true, 
        features: ['Monthly Price', 'Number of Devices', 'Access to Scheduled Programs', 'Bonus Content', 'Download classes']
    },
]


export const study_courses = [
    {
        id: 3,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },
    {
        id: 4,
        imageUrl: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '25.34',
        reviews: 5.0,
        lessons: 10,
        author: 'Kanye West',
        isPaid: true
    },
    {
        id: 5,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },

    {
        id: 6,
        imageUrl: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '25.34',
        reviews: 5.0,
        lessons: 10,
        author: 'Kanye West',
        isPaid: true
    },
    {
        id: 7,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },
    {
        id: 8,
        imageUrl: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '25.34',
        reviews: 5.0,
        lessons: 10,
        author: 'Kanye West',
        isPaid: true
    },
    {
        id: 9,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },
    {
        id: 10,
        imageUrl: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '25.34',
        reviews: 5.0,
        lessons: 10,
        author: 'Kanye West',
        isPaid: true
    },
    {
        id: 11,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },

    {
        id: 12,
        imageUrl: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '25.34',
        reviews: 5.0,
        lessons: 10,
        author: 'Kanye West',
        isPaid: true
    },
    {
        id: 13,
        imageUrl: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg',
        title: 'Basic HTML Courses',
        cost: '27.34',
        reviews: 3.6,
        lessons: 14,
        author: 'Arthur Melo',
        isPaid: true
    },
    
]

export const course_categories = [
    { id: 0, thumbnail: '', progress: 50, courseName: 'Basic HTML Courses', totalLessons: 40, totalDuration: '4hrs', isPaid: false },
    { id: 1, thumbnail: '', progress: 94, courseName: 'Basic HTML Courses', totalLessons: 20, totalDuration: '4hrs', isPaid: false },
    { id: 2, thumbnail: '', progress: 15, courseName: 'Basic HTML Courses', totalLessons: 15, totalDuration: '4hrs', isPaid: false },
    { id: 3, thumbnail: '', progress: 0, courseName: 'Basic HTML Courses', totalLessons: 30, totalDuration: '4hrs', isPaid: false },
    { id: 4, thumbnail: '', progress: 45, courseName: 'Basic HTML Courses', totalLessons: 20, totalDuration: '4hrs', isPaid: false },
    { id: 5, thumbnail: '', progress: 7, courseName: 'Basic HTML Courses', totalLessons: 15, totalDuration: '4hrs', isPaid: false },
]

export const comments = [
    { userName: '', avatar: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg', comment: "I'm doing well, thank you!" },
    { userName: '', avatar: 'https://cdn.pixabay.com/photo/2024/02/12/14/56/woman-8568693_1280.jpg', comment: "I'm doing well, thank you!" },
    { userName: '', avatar: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg', comment: "I'm doing well, thank you!" },
    { userName: '', avatar: 'https://cdn.pixabay.com/photo/2023/03/05/16/44/pier-7831868_1280.jpg', comment: "I'm doing well, thank you!" },
]

export const lessons = [
    { title: 'Welcome!', duration: '3.04', progress: 100 },
    { title: 'What is Python design?', duration: '3.04', progress: 30 },
    { title: 'Python Developer role de...', duration: '3.04', progress: 0 },
] 

export const gender = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
]

const LANDING_FOOTER = {
    company: [
        { name: "About", to: "/about-us" },
        // { name: "Blog", to: "" },
        { name: "Courses", to: "/all-courses" },
        { name: "Instructors", to: "/all-instructors" }
    ],
    legal: [
        { name: "Terms of use", to: "/terms-of-use" },
        { name: "Privacy policy", to: "/privacy-policy" }
    ],
    support: [
        { name: "Help", to: "/help" }
    ],
    connect: [
        { name: "Twitter", src: "twitter.svg", to: "" },
        { name: "LinkedIn", src: "linked-in.svg", to: "" },
        { name: "Instagram", src: "instagram.svg", to: "" },
        { name: "Facebook", src: "facebook.svg", to: "" }
    ],
    download: [
        { to: "", alt: "google_play", src: "googlePlayBtn.svg" },
        { to: "", alt: "app_store", src: "appStoreBtn.svg" },
    ]
}

const FAQ = [
    { 
        id: 1,
        header: "What is SCRiiPO?",
        content: ""
    },
    { 
        id: 2,
        header: "When will SCRiiPO launch",
        content: ""
    },
    { 
        id: 3,
        header: "Who should join the waitlist",
        content: ""
    },
    { 
        id: 4,
        header: "Can I use SCRiiPO for Free?",
        content: ""
    },
    { 
        id: 5,
        header: "Can I upgrade or downgrade my subscripiton plan?",
        content: ""
    },
    { 
        id: 6,
        header: "Is there possibilities of a refund?",
        content: ""
    },
]

const SOCIAL_LINKS = [
    { name: "Twitter", img: "twitter.svg", link: "" },
    { name: "LinkedIn", img: "linked-in.svg", link: "" },
    { name: "Instagram", img: "instagram.svg", link: "" },
    { name: "Facebook", img: "facebook.svg", link: "" }
]

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const NAV_LINKS = [
    {
        id: 1,
        link_name: "courses",
        isDropdown: true,
        to: "",
    },
    {
        id: 2,
        link_name: "pricing",
        isDropdown: false,
        to: "/pricing",
    },
    {
        id: 3,
        link_name: "instructors",
        isDropdown: true,
        to: "",
    },
]



export const ABOUT = <>Kelvin Peter is a renowned music producer with over 15 years of experience, has a passion for teaching and helping new producers 
find their footing in the industry. Holding a degree in Music Production from Academy of art university, 
he has honed his craft through both academic and practical experiences. <br /><br/>
Throughout his extensive career, he has collaborated with a wide range 
of artists across various genres. His versatility and ability to innovate have made him a sought-after producer in the 
industry. He has worked on numerous high-profile projects, helping artists achieve their vision and bringing a distinctive quality to every track he produces. <br /><br/>
 He is dedicated to nurturing
 the next generation of music producers. His teaching philosophy revolves around the belief that every 
 aspiring producer has a unique voice that deserves to be heard. He combines in-depth theoretical knowledge with practical, 
 hands-on training, ensuring that his students are well-equipped to handle real-world production challenges. His courses are designed to be interactive and 
 engaging, providing a balanced mix of instruction and practice. Over the years, Gospel has garnered numerous awards that recognize his exceptional contributions to the music industry. 
 These accolades reflect his talent, hard work, and commitment to excellence. His award-winning projects span multiple genres and demonstrate his ability to consistently deliver top-tier production quality.</>



const COURSES = ['Artist development', 'Music marketing & PR', 'Music business', 'Music production', 'Song writing', 'Sound engineering' ]


const PRIVACY_POLICY = {
    MAIN: <>We value your privacy and are committed to protecting your personal information. <br /><br /> This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform.</>,
    PIBLISH_DATE: "May 24, 2024",
    INFO_WE_COLLECT: <>We gather various types of information to enhance and deliver our services effectively. This includes personal details, usage patterns, and technical data. Personal details are collected when you create an account, enroll in courses, or make payments. Usage patterns help us understand how you interact with our platform, and technical data ensures smooth and secure operation of our services. <br /><br /> To create your profile, you can include necessary information such as your name, email address, and a profile picture. This information helps enhance your experience by enabling better communication with instructors and fellow learners and providing a personalized learning environment. <br /><br /> When subscribing to our platform, we require your payment information, such as your card details, including the expiration date and CVV. For PayPal transactions, you will be redirected to their platform. <br /> <br/> When you use our services, we log data such as usage frequency, access times, interactions with content, course preferences, and shared courses. We also track login activity, email interactions, and IP addresses to manage your platform usage.</>,
    HOW_WE_USE_INFO: <>We use your information to enhance your experience on our platform, providing services, improving our offerings, communicating with you, and ensuring security. This includes delivering your courses, processing payments, and providing customer support. Additionally, your data helps us understand usage patterns, improve our services, and keep you updated on new features and promotions. <br /><br /> <ul ><li>To provide service: Deliver the courses and services you request, process payments, and provide support</li><br /><li>To improve our platform: Analyze usage patterns and feedback to enhance courses, website functionality, and user experience.</li><br /><li>To communicate with you: Analyze usage patterns and feedback to enhance courses, website functionality, and user experience.</li><br /><li>To ensure security: Protect our platform and users from fraudulent activities and ensure compliance with our terms of service</li></ul></>,
    SHARING_YOUR_INFO: <>We respect your privacy and do not sell or rent your personal information. We may share your information with trusted partners to facilitate our services or comply with legal requirements. These partners maintain confidentiality and use your information only for specified purpose. <br /><br /> <ul><li><b>Service providers:</b> Third-party providers who assist in operating our platform, processing payments, and delivering services. These may include cloud hosting services, customer support tools, payment processors, email service providers, and analytics services. Each of these providers is chosen for their commitment to data security and privacy, ensuring your information is handled responsibly and securely. They only have access to the data necessary to perform their functions and are not permitted to use it for other purposes.</li><br /><li><b>Business operations:</b> We may share your information within our company and with third-party business partners who assist in our day-to-day operations. This includes collaboration for improving our services, conducting marketing analysis, and supporting customer relationship management.</li><br /><li><b>Legal obligation:</b> When required by law or in response to legal requests to protect our rights and ensure user safety. This may involve disclosing your information to comply with legal processes, respond to subpoenas or court orders, or protect against legal claims. We may also share your information to investigate or prevent illegal activities, fraud, potential threats to safety, or violations of our terms of service.</li><br /></ul></>,
    DATA_SECURITY: <>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, secure servers, and regular monitoring. However, no method of transmission over the internet or electronic storage is entirely secure.</>,
    YOUR_RIGHTS: <>You have rights regarding your personal information, including accessing, updating, and deleting your data. You can request a copy of your information, correct inaccuracies, or request deletion subject to legal obligations. Additionally, you can opt-out of receiving promotional communications at any time. you have the right to: <br /><br /><ul><li>Request a copy of the personal information we hold about you.</li><li>Correct any inaccuracies in your personal information</li><li>Request the deletion of your personal information, subject to legal obligations.</li><li>Opt-Out rights: Opt-out of receiving promotional communications from us.</li></ul></>,
    COOKIES: <>Our platform uses cookies to enhance your user experience. Cookies are small data files stored on your device that help us understand how you use our platform and improve its functionality. You can manage cookie settings through your browser preferences. Cookies enable us to remember your preferences, personalize content, and analyze site traffic.</>,
    CHANGES_TO_OUR_POLICY: <>We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of significant changes by posting the updated policy on our website and updating the effective date. We encourage you to review this policy regularly to stay informed about how we protect your information.</>
}

const TERMS_OF_USE = {
    MAIN: <>We value your privacy and are committed to protecting your personal information. <br /><br /> This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform.</>,
    PIBLISH_DATE: "May 24, 2024",
    PAR_ONE: <>These Terms of Use govern your access and use of SCRiiPO ( “Company”, “We”, or “Us” ) website located at <a href="/" className="constant_link">www.scriipo.com</a> (the "Site"), mobile applications, and other interactive features we own and operate (collectively, the "Site" and "Services"). By using our Site or Services, you agree to be bound by these Terms. If you don't agree, you shouldn't use our offerings.</>,
    ACCEPTANCE_OF_TERMS: <>By registering for, accessing, browsing, or using our Site, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. We reserve the right to modify these terms at any time, and any changes will be effective immediately upon posting. Your continued use of the Site constitutes acceptance of the revised terms.</>,
    USER_ACCOUNT: <>To access certain features of our Site, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</>,
    USE_OF_SITE: <>You agree to use the Site only for lawful purposes and in accordance with these terms. You agree not to: <br /><br/> <ul><li>Use the Site in any way that violates any applicable local, national, or international law or regulation.</li><li>Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which may harm us or other users of the Site.</li><li>Use the Site to transmit, or procure the sending of, any advertising or promotional material without our prior written consent.</li><li>Impersonate or attempt to impersonate SCRiiPO, a SCRiiPO employee, another user, or any other person or entity.</li></ul></>,
    CONTENT: <>Our Site allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the site, including its legality, reliability, and appropriateness. By posting Content on or through the Site, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Site. You retain any and all of your rights to any content you submit, post, or display on or through the Site and you are responsible for protecting those rights.</>,
    INTELLECTUAL_PROPERTY: <>The Site and its original content, features, and functionality are and will remain the exclusive property of SCRiiPO and its licensors. Our Site is protected by copyright, trademark, and other laws of both Nigeria and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of SCRiiPO.</>,
    PAYMENT_AND_SUB: <>To access certain content and features on our Site, you may need to purchase a subscription. You agree to provide accurate and complete payment information and to update it as necessary. All fees are non-refundable, except as expressly provided in our refund policy. We reserve the right to change subscription fees at any time, and we will notify you of any changes in advance.</>,
    TERMINATION: <>We may terminate or suspend your account and bar access to the site immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms of Use. Upon termination, your right to use the Site will cease immediately. If you wish to terminate your account, you may simply discontinue using the Site.</>,
    LIMITATION_OF_LIABILITY: <>In no event shall SCRiiPO, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from: <br /><br /><ul><li>Your use or inability to use the Site.</li><li>Any conduct or content of any third party on the Site.</li><li>ny content obtained from the Site.</li><li>Unauthorized access, use, or alteration of your transmissions or content.</li></ul></>,
    GOVERNING_LAW: <>These Terms shall be governed and construed in accordance with the laws of Nigeria, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect. <br/><br/>These terms constitute the entire agreement between us regarding our Site and supersede and replace any prior agreements we might have had between us regarding the Site.</>,
    DISPUTE_BTW_USERS: <>You agree that you are solely responsible for your interactions with any other user in connection with the Service and SCRiiPO will have no liability or responsibility with respect thereto. SCRiiPO reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Service.</>,
    CHANGES_TO_TERMS: <>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised terms.</>
}

const ABOUT_SCRIIPO = {
    HEADER: "Empowering creative geniuses to shape the future of music.",
    OUR_STORY: <>SCRiiPO as an idea and concept started in 2020 as a signature tag to music, beats, and sounds produced by the founder, GospelOnDeBeatz. <br/><br/> However, SCRiiPO academy was birthed from realizing that there was knowledge gap among upcoming creatives, especially across Africa.<br/><br/> SCRiiPO was created as a double-edged solution to document the creative process of legends and industry experts and educate younger generation of creatives by exposing them to skilled mentors in the music space and equipping them with the know-how and skills needed to create sounds and musics that are transgenerational.<br/><br/> As we continue to evolve, our commitment to discovering and nurturing new talent remains at the heart of everything we do. We are excited to see what the future holds and look forward to helping even more aspiring music creatives achieve their dreams.</>,
    OUR_MISSION: <>At SCRiiPO, our mission is to discover and nurture the next generation of musical talent across Africa. We are committed to educating and equipping young and upcoming creatives with the knowledge and skills they need to thrive in the entertainment industry.<br/><br/> Through comprehensive training and access to industry opportunities, we aim to empower these individuals to reach their full potential.<br/><br/> By fostering a supportive and innovative learning environment, we strive to create a vibrant community of artists who will shape the future of music and entertainment.</>
}


const responsive = {
    0: { items: 1 },
    300: { items: 2 },
    450: { items: 2 },
    800: { items: 3 },
    1024: { items: 5 },
}

const PAYMENT_CATEGORY = [{text: 'Monthly', value: 0}, {text:'Quarterly', value: 1}, {text:'Bi-Annually', value: 2}]

const CXM = [
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [1,2,3,4], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [2,3,4], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [2,3,4,5,6,8], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [1,2], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [0,3], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [2,3,4], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [1,2,3,4,4,3,2,2,3,4,4,], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [3,6,6,6,6,6], duration: 313445}, 
    {title: "Music Industry Networking and Relationship Building", main_contributor: {name: "Rick Baker"}, description: "Discover how to create a unique brand identity that resonates with your audi...", lessons: [,5,6,7], duration: 313445}, 
]

const USER_INTERESTS = [
    { text: 'Artist development', value: 'artist_development' },
    { text: 'Music business', value: 'music_business' },
    { text: 'Music marketing & Public relation', value: 'music_marketing' },
    { text: 'Music Production', value: 'music_production' },
    { text: 'Song writing', value: 'song_writing' },
    { text: 'Sound engineering', value: 'sound_engineering' },
    { text: 'Song mixing', value: 'song_mixing' },
    { text: 'Vocals', value: 'vocals' },
]

const SKILL_LEVEL = [
    { text: "Beginner", value: "beginner" },
    { text: "Intermediate", value: "intermediate" },
    { text: "Advanced", value: "advanced" }
]


export const BASE_URL = "https://pinnacle-gejv.onrender.com/v1";


export {
    available_classes,
    FAQ,
    SOCIAL_LINKS,
    NAV_LINKS,
    LANDING_FOOTER,
    settings,
    COURSES,
    responsive,
    CXM,
    PAYMENT_CATEGORY,
    PRIVACY_POLICY,
    TERMS_OF_USE,
    ABOUT_SCRIIPO,
    USER_INTERESTS,
    SKILL_LEVEL
}