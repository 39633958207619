import React from 'react'
import { CourseCard, SearchBox } from '../../../components/ui'
import { h3Style, pStyle } from '../../waitList/style';
import { study_courses } from '../../../utils/constants';
import filterImg from '../../../assets/icons/filterBtn.svg';
import { Pagination, Stack } from '@mui/material';

type Props = {}

export default function Main({}: Props) {

    const handleSearch = () => {};


    return (
        <div className="lg:w-[75%] w-full lg:pr-[70px] lg:px-0 px-5">
            <div className='w-full flex items-center gap-x-4'>
                <div className='lg:w-[65%] w-4/5'>
                    <SearchBox placeholder='Search instructor, courses' handleSearch={() => handleSearch} />
                </div>
                <img src={filterImg} alt="filter" className='lg:hidden block lg:w-full w-1/5 hover:cursor-pointer' />
            </div>  

            <div className='mt-8'>
                <h3 className={`${h3Style}`}>All courses</h3>
                <p className={`${pStyle}`}>1024 courses</p>
            </div>   
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-8 mt-10'>
                {study_courses.map((item, i) => (
                    <CourseCard key={i} course={item} />
                ))}
            </div>

            <Stack className='mt-[4rem] lg:float-right'>
                <Pagination count={10} variant="outlined" shape="rounded" />
            </Stack>
        </div>
    )
}