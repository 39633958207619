import React from 'react'
import { GoArrowRight } from 'react-icons/go'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';

function CompleteProfile() {
    const navigate = useNavigate()
    return (
        <div className='border-tsa-primary border rounded-lg h-full flex items-center justify-center flex-col py-2'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center justify-start w-[20%]'>
                    <CircularProgressbar styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: 'butt',
                        textSize: '30px',
                        pathTransitionDuration: 0.5,
                        pathColor: `#1C7272`,
                        textColor: '#1C7272',
                        trailColor: '#d6d6d6',
                    })}
                        value={70} text={"70%"} strokeWidth={10} className='text-sa-primary h-[50px]' />
                </div>
                <div className='w-[80%]'>
                    <div className=''>
                        <h4 className='text-[14px] font-bold supreme'>Complete your profile</h4>
                        <p className='text-[12px] supreme'>You are almost there. Add a picture to personalize your profile</p>
                    </div>
                    <div className='w-full flex items-center justify-start'>
                        <div
                            className="bg-cp-secondary py-2 text-tsa-primary supreme text-[14px] hover:underline cursor-pointer flex justify-center items-center gap-2"
                            onClick={() => { navigate('/app/account') }}
                        >
                            Upload profile picture
                            <GoArrowRight size={20} />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default CompleteProfile