import React, { ButtonHTMLAttributes } from 'react'
import { Link, LinkProps } from 'react-router-dom';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement | LinkProps> {
    isLink: boolean;
    to?: string;
    value: any
};


const inputStyle = 'supreme border border-tsa-primary bg-tsa-primary rounded-[6px] text-[14px] lg:text-[16px] py-[12px] px-[16px] w-full';

export default function CustomButton({ to, value, type, className, onClick, isLink }: Props) {
    return (
        <>
        {isLink ? (
        <Link
            to={to || '/'} 
            className={`${className} ${inputStyle}`}
            >
                {value}
        </Link>
        ) : (
            <button 
                type={type} 
                onClick={onClick}
                className={`${className} ${inputStyle}`}
                >
                    {value}
            </button>
        )}
        </>
    )
}